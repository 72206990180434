.device
  display: flex
  align-items: flex-start
  position: relative
  background-color: var(--card-primary)
  box-shadow: var(--box-shadow-elevation-05)
  border-radius: 2px

.device--disabled
  .device-image-src
    opacity: .4

.device--changed
  background-color: var(--table-active)

  .device-image
    background-color: var(--table-active)

.device--active
  background-color: var(--table-active)
  --color-text: white
  --color-border-light: #{rgba(white, .2)}
  --color-background-black5: #{rgba(white, .1)}
  --color-text-secondary: #{rgba(white, .7)}
  --color-text-light: #{rgba(white, .5)}
  --color-text-super-light: #{rgba(white, .4)}

.device-openMark
  position: absolute
  top: 12px
  left: 12px
  width: 8px
  height: 8px
  z-index: 3
  border-radius: 50%
  background-color: var(--color-error)

.device-icons
  display: flex
  flex-grow: 1
  justify-content: flex-end
  margin-left: 8px

.device-icon-holder:not(:first-child)
  margin-left: 8px

.device-icon
  height: 18px
  width: 18px

.device-image
  width: 80px
  height: 80px
  line-height: 80px
  margin: 8px 0 8px 8px
  padding: 8px
  border-radius: 2px
  flex-shrink: 0
  align-self: center
  position: relative
  background-color: var(--card-primary)

.device-image-src
  width: 100%
  height: 100%
  display: block
  background: center no-repeat
  background-size: contain

.device-image-overlay
  position: absolute
  left: 50%
  width: 100%
  text-align: center
  top: 50%
  font-size: var(--font-size-super-small)
  text-shadow: 0 1px white, 0 0 2px white
  font-weight: 500
  color: var(--text-heading1)
  transform: translate(-50%, -50%)
  text-transform: uppercase

.device-title
  font-size: var(--font-size-medium)
  font-weight: 500
  margin-bottom: 6px
  line-height: 20px
  overflow: hidden
  display: flex
  align-items: center

  .device-title-text
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis

.device-troubles
  line-height: 22px
  text-transform: uppercase

.device-troubles--preenroll
  font-size: var(--font-size-small)
  color: var(--color-warning)
  font-weight: 500

.device-troubles--enrolling
  font-size: var(--font-size-small)
  color: var(--color-warning)

.device-troubles--empty
  font-size: var(--font-size-small)
  color: var(--icon-disabled)

.device-bottom
  display: flex
  flex-direction: row
  align-items: center
  text-transform: uppercase
  color: var(--icon-disabled)
  font-weight: 400
  font-size: 12px
  line-height: 14px

  .device-card-bottom-separator
    margin-right: 4px
    margin-left: 4px
    color: var(--icon-disabled)

  .device-card-clear-status
    &.success
      color: var(--color-success)

    &.failed
      color: var(--color-error)

    &.running
      color: var(--color-info)

.device-card-clear-button
  position: absolute
  top: 29px
  bottom: 29px
  right: 16px
  width: 32px!important
  height: 32px!important

.device-port
  display: block
  position: absolute
  bottom: 16px
  right: 12px
  font-size: var(--font-size-small)
  line-height: 18px
  opacity: var(--opacity-text-secondary)

  + .device-card-clear-status
    padding-right: 64px

.device-content
  padding: 14px 16px
  flex-grow: 1
  max-width: calc(100% - 88px)

.device-hint
  margin-bottom: 10px
  height: 12px
  line-height: 12px
  white-space: nowrap
  display: flex
  align-items: center
  color: var(--text-heading2-subtitle)
  width: 100%

.device-hint-content
  flex-shrink: 1

  &.trimmable
    overflow: hidden

    .tooltip
      text-overflow: ellipsis
      overflow: hidden

  + .device-hint-content
    padding-left: 8px
    margin-left: 8px
    border-left: 1px solid var(--icon-disabled)

[dir = 'rtl']
  .device-hint-content + .device-hint-content
    padding-left: unset
    margin-left: unset
    padding-right: 8px
    margin-right: 8px
    border-left: none
    border-right: 1px solid var(--icon-disabled)

  .device-card-clear-button
    left: 16px
    right: unset

.device-zone
  display: inline-block
  border-radius: 2px
  font-weight: 500
  padding: 2px 3px
  background-color: var(--color-primary)
  color: white
  margin-right: 8px
  text-align: center
  vertical-align: baseline

[dir = 'rtl']
  .device-zone
    margin-right: unset
    margin-left: 8px

.device-modules
  align-self: center
  padding-right: 16px

.device-module
  text-align: left
  font-weight: 500
  white-space: nowrap
  cursor: default

  .communication
    margin-right: 8px

  .device-icon-holder
    display: inline-block

  &:not(:first-child)
    margin-top: 16px

.device-module--notInstalled
  color: var(--icon-disabled)

.device--active .device-module--notInstalled
  color: var(--icon-disabled)

  .communication--none
    color: inherit

.partitions
  font-size: 11px
  color: var(--text-heading2-subtitle)
  margin-left: 4px
  line-height: 13px
  height: 13px
  border-radius: 2px
  background-color: var(--background-partition-label)
  padding: 0 1px
  margin-right: 16px

.partition
  padding: 0 3px

.partition + .partition
  border-left: 1px solid var(--line-general)

.device-walktestMark
  align-self: center
  margin-right: 16px
  background-color: var(--line-general)

  &.success
    color: white
    background-color: var(--color-success)

  &.danger
    color: white
    background-color: var(--color-error)

.device-walktestMark-icon
  position: absolute
  left: 50%
  top: 50%
  transform: translate(-50%, -50%)

.device-shadow
  position: absolute
  width: 100%
  height: 100%
  z-index: 2
  top: 0
  left: 0
  background: var(--background-device-shadow)

.blindModalDevices
  .video
    margin: -16px -32px 0

  .video-definition
    padding-left: 32px
    padding-right: 32px

  .video-title
    padding-left: 33px
    padding-right: 32px

  .video-actions
    border-top: none
    padding-left: 24px
    padding-right: 24px

  .configuration-title
    margin-left: 0
    margin-right: 0

  .tabs
    padding: 0 32px

  .hr
    margin-left: -32px
    margin-right: -32px

  .devicesList
    margin-top: 16px
    width: 100%

  .device
    box-shadow: none
    background-color: var(--button-light)
    margin-bottom: 16px
    margin-right: 32px
    margin-left: 32px

  .block
    margin-left: -32px
    margin-right: -32px
    padding: 0 32px

    > .title
      text-transform: uppercase

  .block:not(:first-child)
    padding-top: 24px
    border-top: 1px solid var(--line-general)

  .processLoader
    margin: 42px

  .card-close
    top: 16px

  .patchTagTable
    margin: 0 -32px

  .sidebar-content
    padding-left: 32px
    padding-right: 32px
    padding-bottom: 32px

    &:has(.clear-widget)
      padding-bottom: 0
      margin-bottom: 0

    .blocks
      width: 100%

      &:has(.clear-widget)
        height: 100%
        display: flex
        flex-direction: column

      .block
        &.clear-button-line
          display: flex
          flex-direction: row-reverse
          padding: 16px 32px
          background-color: var(--background-success)

      .clear-widget
        height: 100%
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        flex-grow: 1
        gap: 24px

        svg.clear-widget-spinner
          transform-origin: center
          animation: dot_spinner_animation .75s step-end infinite

          @keyframes dot_spinner_animation
            8.3%
              transform: rotate(30deg)
            16.6%
              transform: rotate(60deg)
            25%
              transform: rotate(90deg)
            33.3%
              transform: rotate(120deg)
            41.6%
              transform: rotate(150deg)
            50%
              transform: rotate(180deg)
            58.3%
              transform: rotate(210deg)
            66.6%
              transform: rotate(240deg)
            75%
              transform: rotate(270deg)
            83.3%
              transform: rotate(300deg)
            91.6%
              transform: rotate(330deg)
            100%
              transform: rotate(360deg)

        .clear-widget-text
          display: flex
          flex-direction: column
          align-items: center
          gap: 7px

          .clear-widget-title
            font-size: 20px
            color: var(--text-heading1)
            text-transform: uppercase
            line-height: 30px

          .clear-widget-sub-title
            line-height: 24px
            font-size: 16px
            color: var(--text-heading2-subtitle)


  .device-configuration
    .form-row
      margin: 0 -32px
      padding: 0 32px

    .form-row-content
      padding-left: 40px
      position: relative

  .list
    margin-top: 16px

.blindModalDevices-firmware
  .form-field
    flex-grow: 1
    margin-right: 16px

.blindModalDevices-actions
  &:not(:first-child)
    margin-top: 24px

  .processLoader
    margin-top: 24px

.blindModalDevices-device
  box-shadow: none

.diagnostic-index
  font-size: var(--font-size-small)
  color: var(--icon-disabled)
  margin-right: 8px

.device-icon-sub
  background: var(--card-primary)
  color: var(--text-heading1)
  position: absolute
  bottom: -8px
  font-size: var(--font-size-super-small)
  left: 13px
  padding: 1px
  border-radius: 2px


.blindModalDevices-device
  .device-icon-sub
    background: var(--button-light)

[dir = 'rtl']
  .device--panel
    .device-modules
      padding-right: unset
      padding-left: 16px

      .device-module
        text-align: right

        .device-icon-holder
          &:not(:first-child)
            margin-left: unset
            margin-right: 8px

    .communication
      margin-right: unset
      margin-left: 8px

  .device-image
    margin: 8px 8px 8px 0

  .device-port
    right: unset
    left: 12px

  .device-troubles
    padding-right: 0

  .partitions
    margin-left: 16px
    margin-right: 4px
    display: inline-block

  .device-icon-holder
    &:not(:first-child)
      margin-left: unset
      margin-right: 8px

  .blocks
    .definition
      display: block
      direction: rtl !important

      .definition-detail, .definition-title
        padding-right: 32px
        margin-right: 0


.device__info
  line-height: 14px
  padding: 16px 32px
  border-bottom: 1px solid var(--line-general)
  margin-bottom: 10px

  .device__info-row
    display: grid
    grid-template-columns: 140px 1fr

    &:not(:last-child)
      margin-bottom: 14px

  .device__info-label
    font-size: 12px
    text-transform: uppercase
    color: var(--text-heading2-subtitle)

  .device__info-value
    color: var(--text-heading1)
    font-weight: 500

.thermostatControlForm-range, .dimmerControlForm-range
  .range-input
    flex-direction: column
    &.form-field--error
      margin-top: 8px

  .range-container
    max-height: 53px

.range
  &.thermostatControlForm-range--heat
    --track-gradient: var(--gradient-heat)
    --thumb-background-color: var(--color-warning)

.range
  &.thermostatControlForm-range--aux
    --track-gradient: var(--gradient-aux)
    --thumb-background-color: var(--color-error)

.range
  &.dimmerControlForm-range
    --track-gradient: var(--dimmer-track-gradient)
    --thumb-background-color: var(--color-warning)


.dimmerControlForm
  .form-field
    >.form-field-label
      color: var(--text-heading2-subtitle)
      text-transform: uppercase
      font-weight: 500


  .dimmerControlForm-stateButton
    background-color: var(--button-light)

  .dimmerControlForm-stateButton--stateOn
    svg
      color: var(--color-success)

  .dimmerControlForm-stateButton--stateOff
    svg
      color: var(--color-error)

.pgmControlForm
  .form-content
    padding-top: 16px

  .form-field--togglebox
    display: flex
    flex-direction: row-reverse
    justify-content: space-between

    .checkbox-label
      margin-left: 0

.pgmControlForm__stateless
  display: flex
  justify-content: space-between
  align-items: center

.pgmStateParametersControl
  margin-top: 16px

  .disclaimer
    margin-top: 22px

.thermostatFanModeChoose
  display: flex
  align-items: center
  gap: 16px
  margin-top: 40px!important
  justify-content: space-between

  .form-field
    &:not(:first-child)
      margin-top: 0

  .definition
    margin-right: auto

  .checkbox-label
    position: absolute
    right: 50px

[dir = 'rtl']
  .range-container
    position: relative
    padding: 16px 55px 16px 40px
    .range-min
      position: absolute
      left: 10px
    .range-max
      position: absolute
      right: 10px
    .range-element
      direction: ltr

  .range-input
    margin-left: unset
    padding-right: 16px

  .device-had-controls-thermostat-temperatureContainer-current
    direction: ltr

  .device-had-controls-thermostat-temperatureContainer-targetContainer
    .device-had-controls-thermostat-temperatureContainer-target--heat
      position: absolute
      left: 16px
      direction: ltr
    .device-had-controls-thermostat-temperatureContainer-target--cool
      position: absolute
      left: 50px
      direction: ltr

  .thermostatFanModeChoose
    .form-field
      &:not(:first-child)
        margin-top: 0

    .definition
      margin-right: unset
      margin-left: auto

    .checkbox-label
      right: unset
      left: 50px

.batchInfo-table
  margin-bottom: 16px
  border-top: 1px solid var(--line-table)
  border-bottom: 1px solid var(--line-table)

  .table-content
    box-shadow: none
    border-radius: 0
    max-height: calc(100vh - 240px)

  .table-cell:first-child .table-cell-content
    padding-left: 32px

[dir = 'rtl']
  .batches-page
    margin-left: -16px !important

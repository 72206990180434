.groupForm-grid-notifications
  display: grid
  grid-template-columns: 50% 50%
  .form-field
    display: flex
    flex-direction: row-reverse
    justify-content: space-between
    margin-top: 16px
    padding-right: 16px
    .checkbox-label
      margin: 0
    &:nth-child(odd)
      margin-right: 19px

.notifications-profile-header
  border-bottom: 1px solid var(--line-general)
  padding: 0 16px 16px 0
  .form-field
    display: flex
    flex-direction: row-reverse
    justify-content: space-between
    .checkbox-label
      margin: 0

.groupForm-info
  display: grid
  grid-template-columns: repeat(5, 20%)
  border-top: 1px solid var(--line-general)
  grid-gap: 0
  background-color: var(--card-primary)
  .list-item
    width: 100%
    margin-top: 0

.notice-item-border
  grid-column-start: 1
  grid-column-end: 6
  border-bottom: 1px solid var(--line-general)

.group-form-content
  .form-content
    display: grid
    grid-template-columns: 1fr 3fr
    grid-template-rows: 1fr 7fr
    height: 680px
    position: relative
  .card-header
    font-size: 16px
    line-height: 19px
    text-transform: none
    margin: auto 0
    padding: 19px 16px
    grid-column-start: 1
    grid-column-end: 3
    border-bottom: 1px solid var(--line-general)
  .card-content
    display: grid
    grid-template-columns: 1fr 3fr
    grid-column-start: 1
    grid-column-end: 3
    grid-template-areas: "tabs content" "tabs actions"
    padding: 0
  .card-actions
    position: absolute
    right: 0
    bottom: 0
    width: 72%
    border-top: 1px solid var(--line-general)
    margin: 0 16px
    padding: 16px 0
  .form-field-label
    font-size: 14px

.group-form-tabs-wrapper
  grid-row-start: 1
  grid-row-end: 7
  border-right: 1px solid var(--line-general)

.group-form-tab-wrapper
  padding: 21px 16px
  display: flex
  align-items: center
  border-bottom: 1px solid var(--line-general)
  font-weight: 400
  line-height: 14px
  color: var(--text-heading2-subtitle)
  &:hover
    cursor: pointer

.group-form-tabs-content
  grid-area: content
  padding: 20px 16px
  .form-field-label,
  .input-field
    padding-left: 16px
  .form-section.col
    display: grid
    grid-template-columns: repeat(2, 1fr)
    grid-auto-rows: 40px
    width: 100%
    margin-left: 0
    margin-bottom: 30px
    .form-section-header
      font-weight: 500
      font-size: 12px
      line-height: 14px
      text-transform: uppercase
      grid-column-start: 1
      grid-column-end: 4
      margin: 0
      color: var(--text-heading2-subtitle)
    .form-field
      margin: 0
    .form-field--togglebox
      display: flex
      padding-top: 16px
      align-items: center
      .toggle
        order: 1
        margin-left: 25px

.group-tab-common
  width: 100%
  textarea
    max-height: 300px

.power-max-content
  .col
    margin-right: 0 !important
  .dropDown-trigger.form-field
    padding-bottom: 0
  .cols--stretch
    display: block
    .form-section.col
      padding-top: 16px
      border-top: 1px solid var(--line-general)

.iq4-content
  .form-section.col
    grid-template-columns: 100%

.communications-content
  .col
    margin: 0 0 30px 0
  .form-section
    padding-top: 0
  .form-section:nth-child(3)
    padding-top: 20px
    border-top: 1px solid var(--line-general)
  .form-field:nth-child(3)
    margin-left: auto !important

.group-tab-general
  .form-section.col
    padding: 0 0 20px 0
    margin-bottom: 30px
    .form-section-header
      margin: 0 0 20px 0
  .form-section.col:nth-child(1)
    .form-field:nth-child(4)
      grid-column-start: 2
      grid-column-end: 3
      margin-top: 20px
  .form-section.col:nth-child(2)
    display: block
    margin-top: 10px
    padding-top: 20px
    border-top: 1px solid var(--line-general)
    .form-field
      margin: 10px

.group-hidden-tab-content
  display: none

.groups-tab-wrapper--active
  background-color: var(--color-primary30)
  border-left: 2px solid var(--color-primary)
  color: var(--color-primary)

.group-form-section-with-error
  transition: 0.4s
  padding-bottom: 20px

.quazar
  .form-section:nth-child(3)
    padding-top: 20px
    border-top: 1px solid var(--line-general)

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0

input[type=number]
  -moz-appearance: textfield

[dir = 'rtl']
  .group-form-tabs-content
    .col
      &:not(:last-child)
        margin-right: 0

  .group-form-tabs-wrapper
    border-left: 1px solid var(--line-general)

  .group-form-content
    .card-actions
      right: unset
      left: 0
      margin-right: 0

  .groupForm-grid-notifications
    padding-right: unset
    padding-left: 16px
    .form-field:nth-child(odd)
      margin-right: unset
      margin-left: 19px

[dir = 'rtl']
  .notifications-profile-header
    margin-left: 16px

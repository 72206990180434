.bar--children-left:has(.panelStatusLine)
  margin: 0!important
.panelPage-content
  > .bar
    margin: 0 16px 12px 12px
    flex-shrink: 0

  > .scroll
    margin: 12px 12px 0 0
    flex-shrink: 1
    flex-grow: 0
    height: auto

.menu-item--toggle
  padding-top: 4px
  padding-bottom: 4px

  .form-field--togglebox
    line-height: 32px

.panelInteractiveUser-role
    color: var(--role-default-color)
.panelInteractiveUser-role--master
    color: var(--role-master-color)
.panelInteractiveUser-role--supervision
    color: var(--role-supervision-color)

.panelUserCardInfo-label
  color: var(--icon-primary)
  margin-right: 7px

.panelUserCardInfo-index
  color: var(--text-heading2-subtitle)
  background-color: var(--background-partition-label)
  border-radius: 2px

.panelUserCardInfo-userEmail
  margin-left: auto
  margin-right: 16px
  word-break: break-all

.panelUserInfoTable
  .table-row
    padding-left: 32px
    padding-right: 32px

  .table-row:last-child
    border-bottom: 1px solid var(--line-table)

.panelUserInfoTable-row-buttonsCell
  .btn + .btn
    margin-left: 10px

.panelInteractiveUsers-blindModal-tabs
  display: flex
  flex-direction: column
  flex-grow: 1

.panelInteractiveUsers-blindModal-disclaimer
  margin: 32px

.panelInteractiveUsers-blindModal-interactiveUserTabWrapper
  flex-grow: 1

.panelInteractiveUsers-blindModal-card-actions-buttons
  display: flex
  justify-content: space-between

.panelUserInfoTable-labelCell
  max-width: 370px
  overflow: hidden
  margin-right: 10px
  .tooltip
    overflow: hidden
    text-overflow: ellipsis

.c-panel-interactive-user-status-changer
  pointer-events: none
  .primary
    &,.check-input:checked:not([disabled]) + .check-box:not(.check-box--greyscale)
      color: var(--color-success)

.panelInteractiveUsersSearch
  padding: 0 16px 0 0

.panelInteractiveUsersSearch--empty-bar
  margin-bottom: 10px

.panelPage-content
  .panelInteractiveUsersTable
    margin-right: 4px
  .scroll
    margin-top: 0

.panelPage-content:not(:has(.assignUserButton))
  .panelUsers-table.bottom-page-scrolled
    height: calc(100% - 72px) !important

.smartCommunicator-logs
  max-width: 600px
  margin: 0 auto!important

  .table-content
    background: none
    box-shadow: none

    .table-row
      margin-bottom: 10px
      background-color: var(--card-primary)
      box-shadow: var(--box-shadow-elevation-05)

      .smartCommunicator-log-download-icon,
      .smartCommunicator-log-remove-icon
        color: var(--icon-primary)
        z-index: 10
        cursor: pointer

  .log-additional-ifo
    color: var(--text-heading2-subtitle)
    font-weight: 400
    font-size: 12px
    line-height: 14px

.actions-dropDown-container
  z-index: 49

[dir = 'rtl']
  .smartCommunicator-logs
    .configuration-header
      padding: 12px 0 16px 24px
  .panelStatusLine
    padding-left: unset
    padding-right: 16px
    margin-left: unset
    margin-right: 16px
    border-left: none
    border-right: 1px solid var(--line-general)

  .panelUserInfoTable-labelCell
    .partitions
      .partition
        border-left: none
        border-right: 1px solid var(--line-general)

  .panel-page-interactive-users-tad-bar
    margin-left: 0 !important

  .panel-page-logs-tab
    margin-right: -16px
    .configuration-header
      padding-left: 24px

      .bar--children-right
        align-items: flex-start

[dir=rtl]
  .panel__page-top.bar .btn.btn--dropDown
    margin-right: 7px

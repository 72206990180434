.group-nav
  margin-top: -16px
  .tooltip
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis

.groupInfo
  height: 100%
  width: 70%
  margin: 0 auto
  .card-content
    padding: 16px
  .card
    margin-right: 16px
    margin-bottom: 16px
    max-height: calc(100% - 16px)
    display: flex
    flex-flow: column

.groupInfo-description-field
  word-wrap: break-word

.notifications-list
  .card-header
    padding: 4px 0 4px 16px
    text-transform: none
    line-height: 40px
    justify-content: space-between
    display: flex
    background-color: var(--background-success)
  .list-item
    .hint
      color: var(--text-heading2-subtitle)
  .btn
    background-color: transparent
    box-shadow: none
    .btn-content
      padding: 2px 16px



.groupForm-general-card
  margin-bottom: 10px
  .card-header
    display: flex
    justify-content: space-between
    padding: 4px 0 4px 16px
    text-transform: none
    line-height: 40px
    background-color: var(--background-success)
    .btn
      background-color: transparent
      box-shadow: none
      .btn-content
        padding: 2px 16px
  .card-content
    display: grid
    grid-template-columns: repeat(2, 50%)
    border-top: 1px solid var(--line-general)
    grid-gap: 0
    background-color: var(--card-primary)
  .list-item
    margin-top: 0
    .hint
      color: var(--text-heading2-subtitle)

.page-group-settings-bar
  margin-bottom: 0 !important
  height: 45px
  position: relative

.group-customization
  display: flex
  align-content: flex-start
  flex-wrap: wrap
  gap: 10px
  padding-bottom: 16px
  .customization-theme
    position: relative
    display: flex
    flex-wrap: nowrap
    align-content: center
    justify-content: space-between
    align-items: center
    flex-direction: column
    width: 274px
    height: 264px
    background-color: var(--card-primary)
    padding: 32px 32px 18px 32px
    border: 1px solid var(--card-primary)
    &:hover
      background-color: var(--table-hover-table)
      cursor: pointer
    &.customization-theme-chosen
      background-color: var(--message-primary)
      border: 1px solid var(--color-primary)
    &.customization-theme-selected
      background-color: var(--table-active)
      .selected-badge
        color: var(--color-success)
        .check-icon
          position: relative
          margin: 0 9px 0 12px
          width: 12px
          height: 9px
    img
      width: 210px
      height: 170px
    .customization-bottom
      width: 100%
      display: flex
      justify-content: space-between
      color: var(--text-heading2-subtitle)
      font-weight: 500
      font-size: 14px
      .customization-theme-info
        white-space: nowrap
        overflow: hidden
        .form-field--checkbox > label
          vertical-align: middle
          display: inline-block
          max-width: 100%
          text-overflow: ellipsis
          overflow: hidden
      .brightness-switcher
        color: var(--icon-primary)
        margin-left: 10px
        min-width: 24px
        min-height: 24px
        width: 24px
        height: 24px
        &:hover
          color: var(--text-heading1)

.group-customizations-interactiveBar
  &.page-interactiveBar
    margin-left: -16px
    margin-top: 0
    position: absolute
    width: 100%
    & + .group-customization
      margin-top: 0
  &.page-interactiveBar--active + .group-customization
    transform: translateY(var(--bar-height))
    padding-bottom: calc(var(--bar-height) + 16px)

.group-communicating-pager
  margin-right: 8px

@keyframes interactiveBar-animation
  0%
    z-index: 0

  100%
    transform: translate3d(0, 0, 0)
    z-index: 4

.page-content
  z-index: 4

  .page-interactiveBar
    transform: none
    z-index: 0
    transition: none
    margin-top: -16px

    .page-interactiveBar-content
      animation: none
      transform: none!important
      transition: none

      &::after
        display: none

    &.page-interactiveBar--active
      transition: none

      .page-interactiveBar-content
        animation: interactiveBar-animation 0.5s
        transform: none
        box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.1), var(--box-shadow-elevation-05)

.centralStation-page
  .page-content
    z-index: 2

[dir = 'rtl']
  .groupInfo
    .list-item
      padding-right: 0

  .groups-roles-drop-down
    margin-right: unset
    transform: translateX(7px)

  .groups-roles-drop-down-dropDown-container
    .dropDown-content
      .list-item
        padding-right: 32px
        padding-left: unset
        svg
          left: unset
          right: 0

  .groups-table
    .table-cell-content
      .definition
        direction: rtl !important
        justify-content: flex-start

  .group-customizations-interactiveBar
    &.page-interactiveBar
      right: 0

  .page-interactiveBar-content
    .btn
      margin-left: unset
      margin-right: 16px

  .group-communicating-pager
    margin-right: unset
    margin-left: 8px

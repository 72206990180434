.eventsBySerial
  .sidebar
    width: 40%
    max-width: 500px

    > .device
      margin-right: 12px

    .tabs
      margin-top: -6px
      padding: 0 24px 0 0
      margin-left: 0

  .sidebar-content
    padding: 0 16px 16px

    > .spinner-container
      height: auto

  .sidebar-header
    padding: 0 16px

    + .sidebar-content
      padding-top: 16px

.eventsBySerial--panel
  .sidebar
    padding-top: 12px

[dir = 'rtl']
  .eventsBySerial--panel
    padding-left: 0

.panel-system-tests-layout
  display: flex
  height: 100%
  overflow: hidden
  flex-direction: column
  justify-content: flex-start
  gap: 16px

  .panel-system-tests-group-wrapper
    width: 100%
    display: flex
    justify-content: flex-end
    margin-bottom: 16px
    height: 100%
    gap: 24px

    .scroll
      flex-basis: unset

  .panel-system-tests-all-button-wrapper
    width: 100%
    display: flex
    justify-content: flex-end
    padding-bottom: 16px

    .btn--primary
      width: auto

  .panel-system-tests-group-cards
    height: calc(100% - 32px)
    display: flex
    flex-direction: column
    overflow: auto
    padding-right: 8px
    justify-content: flex-start
    width: 25%
    min-width: 320px

    .panel-system-tests-group-card
      width: 100%
      padding: 16px
      margin: 0 0 10px 0
      background-color: var(--card-primary)
      display: flex
      align-items: center
      justify-content: space-between

      &:last-child
        margin-bottom: 38px !important

      &.active-group
        background-color: var(--table-active)

      .btn
        margin-right: 7px
        background-color: var(--button-light)

.panel-system-tests-group-button
  display: flex
  align-items: center
  justify-content: flex-end
  min-width: 187px

.panel-system-tests-info-cards-wrapper
  height: 100%

.panel-system-grouped-test-info-card
  &:last-child
    margin-bottom: 30px !important

  .card-content
    background-color: var(--card-primary)

.panel-system-tests-group-info
  font-weight: 500
  line-height: 16px
  color: var(--text-heading1)

  .panel-system-tests-group-icons
    display: flex
    margin-top: 7px
    width: 100%

    .panel-system-tests-group-icon-delimiter
      margin-right: 5px
      height: 16px
      width: 1px
      background-color: var(--text-heading2-subtitle)

    .panel-system-tests-group-icon-wrapper
      margin-right: 6px
      display: flex
      align-items: flex-end
      font-size: 12px
      line-height: 14px

      svg
        margin-right: 6px

    .panel-system-tests-group-icon-started
      color: var(--color-info)

    .panel-system-tests-group-icon-passed
      color: var(--color-success)

    .panel-system-tests-group-icon-failed
      color: var(--color-error)

  .info-data
    font-size: 12px
    font-weight: 400
    line-height: 14px
    margin-top: 7px
    color: var(--text-heading2-subtitle)

.panel-system-tests-info-cards
  width: 60%
  height: 100%
  padding-bottom: 24px

  .testPassed
    color: var(--color-success)
  .testFailed
    color: var(--color-error)
  .testInProgress
    color: var(--color-info)

  .btn
    right: 16px !important
    top: 50%
    font-size: 12px
    line-height: 16px
    height: 28px
    background-color: var(--button-light)

[dir = 'rtl']
  .panel-system-tests-info-cards
    padding-right: unset
    padding-left: 16px
    .btn
      right: unset !important

  .panel-system-tests-layout
    .btn--primary
      margin: 0 auto 0 0

  .panel-system-tests-group-button
    .panel-system-tests-group-button-arrow
      svg
        transform: rotate(180deg)

  .panel-system-tests-group-card
    .btn
      margin-right: 0
      margin-left: 7px

  .panel-system-tests-group-icon-wrapper
    margin-right: 0 !important
    margin-left: 6px

    svg
      margin-right: 0 !important
      margin-left: 6px

  .panel-system-tests-group-icon-passed
    color: var(--color-success)

    &::after
      margin-right: 4px
      margin-left: 0 !important

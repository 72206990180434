.settings-card
  max-width: 1200px
  margin: 0 auto 10px

  .card-header
    padding: 16px
    background-color: var(--table-hover)
    border-bottom: 1px solid var(--line-general)
    display: flex
    text-transform: none
    font-size: var(--font-size-primary)
    position: relative

    .btn
      position: absolute
      right: 6px
      top: 50%
      transform: translateY(-50%)

  .card-content
    padding: 16px

  .hint
    color: var(--text-heading2-subtitle)

  .list-item:not(:first-child)
      margin-top: 16px

  .list-item-bordered
    padding-bottom: 16px
    border-bottom: 1px solid var(--button-light)

  .list--twoCols
    align-items: unset

.settings-card-disclaimer
  margin-top: 16px

[dir = 'rtl']
  .editSettingsCard
    .input-icon
      right: unset
      left: 4px

    .alarmsSetting
      .col
        margin-right: unset
        margin-left: 16px

    .soundPlaySelect
      .soundPlaySelect-play
        left: unset
        right: 3px

    .soundPlaySelect__isPlay
      .input-field
        padding-right: 26px
        padding-left: unset

.panelInstallers-table
  .table-content
    margin-right: 16px
    .installer-status-checkbox
      pointer-events: none
  &.bottom-page-scrolled
    height: calc(100% - 70px) !important
    .table-content
      padding-bottom: 0!important

[dir = 'rtl']
  .panelInstallers-table
    margin-left: 0 !important
    .table-content
      margin-left: 0
      margin-right: 0

  .panel-page-installers-tab
    margin-left: 0

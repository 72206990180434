.devices--had
  > .bar
    margin-right: 0

.deviceHad
  .device-image
    display: flex
    justify-content: center
    align-items: center
  .device-content
    display: grid
    grid-template-areas: "device-hint device-had-controls" "device-title device-had-controls" "deviceHad-state device-had-controls"

    .device-hint
      grid-area: device-hint

      .device-zone
        background-color: var(--background-partition-label)
        color: var(--text-heading1)

      .device-hint-content
        color: var(--text-heading2-subtitle)

      .device-hint-content--primary
        color: var(--color-primary)

    .device-title
      grid-area: device-title
    .deviceHad-state
      grid-area: deviceHad-state

.deviceHad-simple-pgm svg
    *
      fill: currentColor
      fill-opacity: 1

.deviceHad-ArmMode-type.deviceHad-enabled
  color: var(--color-warning)

.deviceHad-ArmToggle-type.deviceHad-enabled
  color: var(--color-error)

.deviceHad-Disarm-type.deviceHad-enabled
  color: var(--color-success)

.deviceHad-enabled
  color: var(--color-primary)

.deviceHad-disabled
  color: var(--icon-disabled)

.deviceHad-stateless
  color: var(--icon-default)

.deviceHad-state
    font-size: 12px
    color: var(--text-heading2-subtitle)
    display: flex

    >svg
      height: 1em
      width: auto
      margin-right: 6px

    &.active
      color: var(--color-success)

    &.jammed
      color: var(--color-error)

.device-had-controls
  grid-area: device-had-controls
  display: flex
  justify-content: flex-end
  align-items: center

  .btn
    &:not(.btn--disabled)
      &:active, &:focus
        box-shadow: var(--box-shadow-elevation-2)

.device-had-controls-hadParamsBtn
  margin-right: 16px

  .btn
    box-shadow: var(--box-shadow-elevation-05)

.device-had-controls-stateButtons
  box-shadow: var(--box-shadow-elevation-05)

  .btn:first-child
    border-bottom-right-radius: 0
    border-top-right-radius: 0

  .btn:last-child
    border-bottom-left-radius: 0
    border-top-left-radius: 0
    border-left: 1px solid var(--line-table)


.device-had-controls-stateButtons--doorlock
  margin-top: auto
  margin-bottom: auto

  .btn
    svg
      color: var(--color-success)

  .btn.btn--disabled
    svg
      color: currentColor

.device-had-controls-dimmer
  margin-top: auto
  margin-bottom: auto

.device-had-controls-dimmer-value
  font-size: 18px
  line-height: 21px

.device-had-controls-thermostat
  margin-top: auto
  margin-bottom: auto
  display: flex
  flex-direction: column
  align-items: flex-end

.device-had-controls-thermostat-temperatureContainer-current
  line-height: 26px

  strong
    font-size: 18px

.device-had-controls-thermostat-temperatureContainer-current-label
  color: var(--text-heading2-subtitle)

.device-had-controls-thermostat-temperatureContainer-target
  line-height: 26px
  font-weight: 500

  & + &
    &:before
      color: var(--text-heading2-subtitle)
      content: ' / '

.device-had-controls-thermostat-temperatureContainer-target--heat
  color: var(--color-error)

.device-had-controls-thermostat-temperatureContainer-target--cool
  color: var(--color-info)

.hadBlindModal
  .deviceHad
    border-radius: 2px
    box-shadow: none
    margin-right: 32px
    margin-left: 32px
    background-color: var(--button-light)

    .device-image
      width: 70px
      height: 70px
      padding: 12px
      margin: 16px
      background-color: var(--background-success)

      > svg
        width: 46px
        height: 46px

    .device-content
      padding: 16px

  .tabs
    padding-left: 32px
    padding-right: 32px

  .sidebar-content
    padding-left: 32px
    padding-right: 32px
  .device--active
    background-color: var(--button-light)
    box-shadow: none
    border: none
  .device-title
    color: var(--text-heading1)
    overflow: hidden
    display: flex
    align-items: center
    .device-title-text
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis

[dir = 'rtl']
  .disclaimer--error
    position: relative
    direction: initial
    padding-left: 50px
    svg
      position: absolute
      right: 50px

  .devices--had
    .disclaimer--error
      position: relative
      direction: initial
      padding-right: 24px
      svg
        position: absolute
        right: 4px

  .device-had-controls
    .btn--dropDown
      &:before, &:after
        left: unset
        right: 0

  .deviceHad
    .deviceHad-state
      svg
        margin-right: unset
        margin-left: 6px

.interactiveUserBlind-info
  flex-grow: 1
  display: flex
  justify-content: space-between

  .interactiveUserBlind-info-section
    display: flex
    flex-direction: column
    justify-content: center

  .interactiveUserBlind-info-item
    word-break: break-all

  .interactiveUserBlind-info-item + .interactiveUserBlind-info-item
    margin-top: 6px

  .interactiveUserBlind-info-icon
    width: 16px
    margin-right: 6px
    margin-bottom: 4px
    color: var(--icon-primary)

  .interactiveUserBlind-info-link
    color: var(--color-primary)

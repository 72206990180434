.customerGeneralInfo
  display: grid
  grid-template-areas: "ava name name name" "ava email phone address"
  grid-template-columns: 40px auto auto auto
  grid-column-gap: 16px
  grid-row-gap: 10px

.customerGeneralInfo-item
  font-size: 12px

=itemMixin
  line-height: 14px

  >svg
    margin-right: 1em
    height: 13px

.customerGeneralInfo-item--ava
  grid-area: ava
  width: 40px
  height: 40px
  line-height: 40px
  color: var(--text-heading1)!important
  background-color: var(--line-general)!important

.customerGeneralInfo-item--name
  font-size: 14px
  line-height: 16px
  grid-area: name
  +itemMixin

.customerGeneralInfo-item--email
  grid-area: email
  +itemMixin

.customerGeneralInfo-item--phone
  grid-area: phone
  +itemMixin

.customerGeneralInfo-item--address
  grid-area: address
  +itemMixin

.minimizeMap
  top: 16px
  right: 12px
  position: absolute
  width: 44px
  height: 44px
  background: rgba(white, 0.95)

  .btn-content
    padding: 0
    display: flex
    flex-direction: row
    align-content: center
    justify-content: center
    align-items: center

[dir = 'rtl']
  .customerGeneralInfo-item--email
    svg
      margin-left: 1em
      margin-right: unset

  .customerRemarks
    .borderedDefinition--icon
      svg
        margin-right: unset
        margin-left: 16px

  .customerGeneralInfo-item--address
    svg
      margin-right: unset
      margin-left: 1em

  .customerGeneralInfo-item--phone
    svg
      margin-right: unset
      margin-left: 1em

@media screen and (min-width: $window-large)
  .customerGeneralInfo
    display: flex
    align-items: center

  .customerGeneralInfo-item--email
    margin-left: auto

  [dir = 'rtl']
    .customerGeneralInfo-item--email
      margin-right: auto
      margin-left: unset

@media screen and (min-width: $window-x-large)
  .customerGeneralInfo
    display: grid
    align-items: initial

  .customerGeneralInfo-item--email
    margin-left: initial




.privacyPolicy-link
  display: flex
  flex-direction: row
  align-items: center
  gap: 8px
  padding: 8px 12px 8px 8px
  font-size: 14px
  line-height: 20px
  text-transform: uppercase
  font-weight: 500
  color: var(--text-additional)

  svg
    color: var(--color-primary)

  &:hover
    transition: 0.3ms
    text-decoration: underline

.eventsInterface
  .privacyPolicy-link
    position: absolute
    bottom: 8px
    right: 8px

.login
  .privacyPolicy-link
    position: absolute
    bottom: 16px
    right: 16px

.theme--sanMarino
  .eventsInterface
    .privacyPolicy-link
      color: var(--text-heading1)

[dir="rtl"]
  .eventsInterface
    .privacyPolicy-link
      right: unset
      left: 8px

  .login
    .privacyPolicy-link
      right: unset
      left: 16px

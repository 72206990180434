.logo
  text-align: left
  display: block
  font-weight: bold
  font-size: 28px
  line-height: 0.9
  letter-spacing: -1.5px
  margin-left: 24px

.logo--custom
  height: 50px

  > svg
    display: block
    height: 50px
    width: initial

.logo-icon
  width: 80px
  height: 44px

.logo
  text-align: left
  display: block
  font-weight: bold
  font-size: 28px
  line-height: 0.9
  letter-spacing: -1.5px

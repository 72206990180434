.soundPlaySelect
  position: relative

  .form-field:not(:first-child)
    margin-top: 0

  .soundPlaySelect-play
    position: absolute
    top: 21px
    left: 3px
    height: 20px
    width: 20px
    z-index: 1
    cursor: pointer

.soundPlaySelect__isPlay .input-field
  padding-left: 26px !important

.soundPlaySelect-option
  background: linear-gradient(to right, var(--color-primary30) 50%, transparent 50%) right bottom / 200% 100%
  background-size: 200% 100%

  &.soundPlaySelect-option__noSoundPlay:hover
    background: var(--button-light)

  &:not(.soundPlaySelect-option__noSoundPlay):hover
    background: linear-gradient(to right, var(--color-primary30) 50%, var(--button-light) 50%) left bottom / 200% 100%
    transition: background 2s ease-out .5s

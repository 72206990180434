.sidebar-pinned
  .partitionDevices-sidebar
    margin-right: 16px

.panelState
  padding-bottom: 0 !important

  > .scroll
    margin-top: 16px

  .panelState-devicesTools
    .tabs
      margin-bottom: 0

    .scroll
      margin-top: 0

    .tabs-layout-container
      max-height: 460px

  .panelState-partition
    .btns
      .btn
        &.button-state-target
          opacity: 38%

          +.btn
            opacity: 38%

          svg
            transform-origin: center
            animation: dot_spinner_animation .75s step-end infinite

            @keyframes dot_spinner_animation
              8.3%
                transform: rotate(30deg)
              16.6%
                transform: rotate(60deg)
              25%
                transform: rotate(90deg)
              33.3%
                transform: rotate(120deg)
              41.6%
                transform: rotate(150deg)
              50%
                transform: rotate(180deg)
              58.3%
                transform: rotate(210deg)
              66.6%
                transform: rotate(240deg)
              75%
                transform: rotate(270deg)
              83.3%
                transform: rotate(300deg)
              91.6%
                transform: rotate(330deg)
              100%
                transform: rotate(360deg)

            path
              fill: var(--icon-default)

      &.btn--primary
        .btn
          color: var(--text-additional)

          svg
            path
              fill: var(--text-additional)

  .table-row--active
    position: relative
    background-color: var(--table-active)
    color: var(--text-heading1)

    &:before
      content: ''
      width: 7px
      height: 100%
      position: absolute
      background-color: var(--table-active)
      mask: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9JzEwMCcgaGVpZ2h0PScxMDAnPjxwYXRoIGQ9Ik0gMTAwLDUwIDAsMTAwIDAsMCB6IiBmaWxsPSJjdXJyZW50Q29sb3IiLz48L3N2Zz4=)
      mask-size: 100% 100%
      top: 0
      left: 100%

  .definition-detail
    font-size: var(--font-size-small)
    font-weight: 500
    text-transform: uppercase

[dir="rtl"] .panelState
  .table-row--active:before
    transform: rotate(180deg)

.panelState-readyState
  background-color: rgba(0, 0, 0, .07)
  color: var(--text-heading2-subtitle)

  .circle-icon
    width: 16px
    height: 16px

.panelState-devicesTools

  .scroll
    margin-top: 16px
    padding-top: 0
    border-top: 1px solid var(--line-general)

  .card-header
    background-color: var(--background-success)
    padding: 16px 32px
    display: flex
    align-items: center
    border-bottom: 1px solid var(--background-success)

    .partitionDevices-sidebar-setLabelBtn
      margin-left: auto

    .card-close
      position: static
      margin-left: auto
      margin-right: -9px

    .partitionDevices-sidebar-setLabelBtn + .card-close
      margin-left: 7px

  .tabs
    padding: 0 32px

  .tabs-layout
    .error
      width: 274px

  .tabs-layout-container
    flex-grow: 1
    overflow-y: auto

  .partitionDevices-sidebar-openedDevices.tab--active
    color: var(--color-error)

    &::after
      background-color: var(--color-error)

  .device
    &:not(:first-child)
      border-top: 1px solid var(--line-general)

    .device-port
      opacity: 1

  .card-actions
    background-color: var(--background-success)
    border-top: 1px solid var(--line-general)
    padding: 16px 32px

    &:empty
      display: none

.partitionDevices-sidebar-title
  text-overflow: ellipsis
  overflow: hidden
  white-space: nowrap
  margin: 0 16px
  .tooltip
    text-overflow: ellipsis
    overflow: hidden
    white-space: nowrap


.partitionDevices-sidebar-bypassAll-container
  background-color: var(--background-success)
  display: flex
  padding: 18px 32px
  align-items: center
  justify-content: space-between

  .form-field
    margin-top: 0
    flex-shrink: 0

.partitionDevices-sidebar-bypassAll-title
  font-size: 12px
  line-height: 14px
  text-transform: uppercase
  color: var(--text-heading2-subtitle)

.partitionDevices-sidebar
  .bottom-list-scrolled
    &:last-child
      margin-bottom: 0

.page
  > .page-interactiveBar-container
    animation: none
    transform: none !important
    transition: none
    z-index: 4

    .page-interactiveBar-items
      margin-top: -16px

      .page-interactiveBar
        animation: interactiveBar-animation-back 0.5s
        transition: none

        > .page-interactiveBar-content
          animation: none
          transform: none !important

          &::after
            display: none

      .page-interactiveBar--active
        animation: unset

        > .page-interactiveBar-content
          animation: interactiveBar-animation 0.5s
          transform: none !important
          box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.1), var(--box-shadow-elevation-05)

@keyframes interactiveBar-animation
  0%
    z-index: 0

  100%
    transform: translate3d(0, 0, 0)
    z-index: 4

@keyframes interactiveBar-animation-back
  0%
    z-index: 4

  100%
    transform: translate3d(0, 0, 0)
    z-index: 0
    opacity: 1

[dir = 'rtl']
  .partitionDevices-sidebar
    margin-left: 0

    .card-header
      display: flex
      justify-content: space-between

      .partitionDevices-sidebar-title
        margin-left: auto

      .card-close
        margin-left: 9px
        margin-right: 7px

    .device-openMark
      left: unset
      right: 12px

    .btns
      svg
        transform: rotate(180deg)

  .panelState
    .table
      margin-right: unset
      margin-left: 18px

    .table-row--active:before
      left: unset
      right: 100%

  .panelState-partition
    .definition
      display: contents

  .partitionDevices-sidebar-setLabelBtn
    margin-left: 0 !important
    margin-right: auto

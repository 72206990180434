.sidebar-pinned
  .rri
    .sidebar
      margin-right: 16px

.rri
  margin-right: 0 !important

  .sidebar
    max-width: 650px
    width: 50%
    padding-left: 0
    margin-left: 12px

  .table-row
    .rri-next-date
      .definition
        display: flex
        align-items: center
        flex-direction: row

  .table-cell:last-child .table-cell-content
    padding-left: 0

  .table-row--active
    position: relative
    background-color: var(--table-active)
    color: var(--text-heading1)
    &::before
      background-color: var(--table-active) !important
    .rri-caption--failed, .rri-caption--error
      color: var(--color-error)
    .rri-caption--success
      color: var(--color-success)
    .rri-caption--running
      color: var(--color-primary)
    .rri-caption--pending
      color: var(--icon-disabled)

    &:before
      content: ''
      width: 7px
      height: 100%
      position: absolute
      background-color: var(--color-primary)
      mask: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9JzEwMCcgaGVpZ2h0PScxMDAnPjxwYXRoIGQ9Ik0gMTAwLDUwIDAsMTAwIDAsMCB6IiBmaWxsPSJjdXJyZW50Q29sb3IiLz48L3N2Zz4=)
      mask-size: 100% 100%
      top: 0
      left: 100%

.rri-next
  .rri-status
    margin: 0 12px
    opacity: var(--opacity-text-secondary)

  .progress
    margin-right: 12px

.rri-next-date
  font-weight: 500
  margin-left: 12px

.rri-caption
  overflow: hidden
  text-overflow: ellipsis
  font-weight: normal

.rri-caption--error
  color: var(--color-error)

.rri-caption--running
  color: var(--color-primary)

.rri-caption--failed
  color: var(--color-error)

.rri-caption--pending
  color: var(--icon-disabled)

.rri-caption--success
  color: var(--color-success)

.table-row--active
  .rri-caption
    color: currentColor

  .rri-caption--pending
    opacity: var(--opacity-text-secondary)

.rri-progress
  width: 70px
  margin-left: auto
  margin-right: 8px

.rri-actions
  display: flex
  justify-content: flex-end

.rri-action
  display: inline-block
  height: 36px
  width: 34px
  line-height: 34px
  text-align: center

  .calendar-item-icon &
    height: 24px
    width: 24px
    line-height: 24px
    display: flex
    align-items: center
    justify-content: center

.rri-action-icon
  vertical-align: middle
  max-height: 16px

.rri-action-icon--viewed
  transform: translate(2px, 1px)

.rri-action-icon--notViewed
  transform: translate(-1px, 0)

.rri-action-icon--sent
  transform: translate(4px, 0)

.rri-results-content
  max-height: 100%
  overflow-y: auto

.rri-result-details-row
  min-width: 20%
  padding: 8px 24px
  .definition-title
    font-weight: normal

.rri-result-details-row:first-child
  padding-left: 0

.rri-result-details-time
  color: var(--text-heading2-subtitle)
  font-weight: normal
  white-space: nowrap
  font-size: var(--font-size-small)

.rri-bar
  margin: 12px 12px 16px 0

  .form-field--datepicker
    width: 250px
    margin-left: 12px

.rri-nearest
  display: flex
  align-items: center

.blindModalPanelRemoteInspection
  .card-content
    padding: 0
    display: flex
    flex-direction: column
    height: calc(100% - 68px)
  .card-header
    display: flex
    border-bottom: 1px solid var(--line-general)
    padding: 16px 26px 16px 32px
    height: 68px
  .card-close
    top: 14px
    right: 17px

.rri-sidebar .sidebar-content
  padding: 0

.rri-header, .rri-footer
  padding: 16px 26px 16px 32px
  display: flex
  align-items: center

.rri-header
  border-bottom: 1px solid var(--line-general)
  background-color: var(--background-success)

  .btns
    margin-right: 16px

.rri-header-account
  margin-left: 5px
  color: var(--text-heading2-subtitle)

.rri-header-serial
  font-weight: 500

.rri-footer
  justify-content: space-between
  border-top: 1px solid var(--line-general)
  background-color: var(--background-success)

.rri-footer-btns button:not(:first-child)
  margin-left: 10px

[dir = 'rtl']
  .rri-footer-btns button:not(:first-child)
    margin-left: unset
    margin-right: 10px

.rri-tabs
  padding: 0 32px
  box-shadow: inset 0 -1px var(--line-general)
  margin-top: 24px
  .tab:first-child.tab--active
    color: var(--color-error)
    &::after
      background-color: var(--color-error)
  .tab:nth-child(2).tab--active
    color: var(--color-warning)
    &::after
      background-color: var(--color-warning)
  .tab:last-child.tab--active
    color: var(--color-success)
    &::after
      background-color: var(--color-success)

.rri-content
  max-height: 100%
  overflow-y: auto
  padding-top: 16px

.rri-result
  &:first-child
    margin-top: 16px
  &:not(:first-child)
    margin-top: 24px
  &:last-child
    margin-bottom: 16px
  .rri-result-details
    width: 100%
    thead th
      background-color: var(--background-success)
      text-align: left
      color: var(--text-heading2-subtitle)
      text-transform: uppercase
      font-size: 12px
      font-weight: normal
    thead th, tbody td
      padding: 8px 0 8px 32px
    tbody tr
      border-bottom: 1px solid var(--line-table)

.rri-result-header
  margin-bottom: 10px
  padding-left: 32px
  display: flex
  align-items: center

.rri-result-range
  padding-left: 7px
  color: var(--text-heading2-subtitle)

.rri-result-title
  text-transform: uppercase
  font-size: 12px
  font-weight: 500

.rri-next-ri
  display: flex
  justify-content: space-between
  margin: 40px 32px 10px 32px
  border-radius: 2px
  padding: 18px 16px
  border: 2px solid var(--line-general)

.rri-next-ri-date
  display: grid
  grid-template-columns: repeat(2,max-content)
  grid-column-gap: 24px
  align-items: center

.rri-next-ri-section
  display: grid
  grid-template-columns: repeat(2,max-content)
  grid-column-gap: 7px
  align-items: end
  font-size: 12px

.rri-next-ri-section--current svg path
  fill: var(--color-primary)

[dir = 'rtl']
  .rri
    .rri-header
      display: inline-block
      .btns
        margin-right: unset
        margin-left: 16px
        .btn-content
          transform: rotate(180deg)
    .table
      margin-right: unset
      margin-left: 18px

    .table-row--active:before
      left: unset
      right: 100%
      transform: rotate(180deg)

  .rri-result-details-row
    padding: 8px 32px 8px 0
    .definition
      direction: initial !important

  .rri-result-details tbody td, .rri-result-details thead th
    text-align: right
    padding: 8px 32px 8px 0

  .rri-results-content
    .rri-result-header
      padding-left: unset
      padding-right: 32px
      .rri-result-range
        padding-left: unset
        padding-right: 7px

  .rri-next-ri-section--current
    position: relative
    .definition-detail
      margin-right: 20px
    svg
      position: absolute
      right: 0

  .rri-next-ri-date
    .rri-next-ri-section
      &:first-child
        position: relative
        .definition-detail
          margin-right: 20px
        svg
          position: absolute
          right: 0

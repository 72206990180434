.emptyPage
  width: 100%
  height: 100%
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center

  > svg
    margin-bottom: 19px

  .error
    margin: 0 0 24px 0
    padding: 0
    height: initial
    min-height: initial

    h1
      margin: 0
      text-transform: uppercase
      font-size: 20px

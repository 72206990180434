.blindModal-card-tableWrapper
  margin-bottom: 24px

  .table-header
    background-color: var(--background-success)
    color: var(--text-heading2-subtitle)
    border-bottom: 1px solid var(--line-table)

  .table-header-cell
    font-weight: normal
    padding: 6px 8px

  .table-header-cell:first-child
    padding-left: 32px

  .table-content
    box-shadow: none

  .table-row, .table-header
    padding-left: 0

  .interactiveUserPanels-title
    padding: 16px 32px

  .table-row:last-child
    border-bottom: 1px solid var(--line-table)

  .table-cell:first-child
    .table-cell-content
      padding-left: 32px

  .table-cell:last-child
    .table-cell-content
      padding-right: 32px
      text-align: center

      .btn-content
        padding: 0 7px

        .btn-icon
          height: 14px

.blindModal-card-tableWrapper-title
  font-size: 12px
  font-weight: 500
  text-transform: uppercase
  padding: 16px 32px

.blindModal-card-tableWrapper-title-count
  font-weight: normal
  color: var(--text-heading2-subtitle)
  margin-left: 7px

.main-sidebar-processes
  display: flex
  flex-flow: column

.main-sidebar-processes-content
  margin-top: 10px
  margin-bottom: 10px
  overflow-y: auto

[dir = 'rtl']
  .main-sidebar-processes-content
    .process-title-icon
      left: 0
      right: unset
    .process-title
      padding-right: unset
      padding-left: 12px

.process
  padding: 12px
  padding-bottom: 24px
  position: relative
  overflow: hidden
  &.isNew
    animation: slideFromLeft calc(var(--time) * 3) var(--ease-md)
    transition: padding var(--time) var(--ease-md), background-color var(--time) var(--ease-md)

  .process-counters
    overflow: hidden
    position: absolute
    left: 0
    bottom: 12px
    width: 100%
    padding: 0 12px

  .process-counter
    border-bottom-width: 4px
    height: 0
    border-radius: 5px

  &:not(:first-child)
    margin-top: 12px

.main-sidebar-process--active
  background-color: var(--line-general)

.process--accent
  background-color: var(--table-active)!important

@keyframes slideFromLeft
  from
    opacity: 0
    transform: translate3d(-100%, 0, 0)

  to
    opacity: 1
    transform: translate3d(0, 0, 0)

.process-title
  margin-bottom: 2px
  font-weight: 500
  position: relative
  padding-right: 12px

  .hint
    margin-bottom: 2px
    overflow: hidden
    text-overflow: ellipsis
    text-transform: none
    font-size: var(--font-size-small)

.process-type
  overflow: hidden
  text-overflow: ellipsis
  max-width: 324px

.process-title-icon
  position: absolute
  right: 0
  top: 0
  width: 14px
  height: 14px
  cursor: pointer
  opacity: var(--opacity-text-secondary)

.process-time
  opacity: var(--opacity-text-secondary)
  font-size: var(--font-size-small)

.process-counters
  display: flex

.process-counter
  transition: height  var(--time) var(--ease-md), border-bottom  var(--time) linear
  border-bottom: 4px solid
  line-height: 1
  flex-grow: 1
  flex-shrink: 1
  text-align: center
  height: 24px

.process-counter--succeeded
  border-bottom-color: var(--color-success)

.progress-bar--indeterminate-started:after,.progress-bar--indeterminate-started:before
  background-color: var(--color-success)

.process-counter--failed
  border-bottom-color: var(--color-error)

.process-counter--handled
  border-bottom-color: var(--color-warning)

.process-counter--start
  border-bottom-color: var(--color-info)

.process-counter--not-start
  border-bottom-color: var(--icon-primary)

.process-counter-label
  color: var(--text-heading2-subtitle)
  transition: opacity var(--time) var(--ease)
  padding: 0 4px
  font-size: 10px
  font-weight: 500

.processError-tooltip
  width: 30px

  svg
    color: var(--icon-default)

.process-table
  .process-userInfo
    text-overflow: ellipsis
    overflow: hidden
    .email
      display: block
      width: 100%
      text-overflow: ellipsis
      overflow: hidden
      color: var(--text-heading2-subtitle)

.processError-info-content
  display: flex
  flex-direction: column
  align-items: flex-start
  justify-content: flex-start
  gap: 10px
  padding: 5px
  width: 300px

  h5
    font-size: 12px
    line-height: initial
    padding-bottom: 10px
    width: calc(100% - 15px)
    text-align: left
    text-transform: uppercase
    color: var(--color-error)
    border-bottom: 1px solid var(--line-general)

  p
    width: calc(100% - 15px)
    max-width: calc(100% - 15px)
    word-break: break-word
    display: flex
    text-align: left
    font-size: 14px
    line-height: 20px
    color: var(--text-heading1)
    max-height: 200px
    overflow: auto

[dir='rtl']
  .processError-info-content
    h5
      text-align: right

    p
      text-align: right

.theme--sanMarino
  .processError-info-content
    p
      color: var(--text-additional)

[dir = 'rtl']
  .process-table-definition-cell
    position: relative
    padding-right: 24px
    svg
      position: absolute
      right: 0
      margin-right: 0

  .runners-table-process-type-cell
    position: relative
    padding-right: 24px
    svg
      position: absolute
      right: 0
      margin-right: 0

  .panel-page-processes-tab
    margin-left: -16px !important

  .process-title-icon
    right: unset
    left: 0

.crossServerBlind
  .search-bar
    background-color: var(--background-primary)
    margin-bottom: 24px

.crossServerBlind-servers
  margin: 0 -32px 32px
  padding: 0 32px
  padding-bottom: 12px
  border-bottom: 1px solid var(--line-general)

  .definition
    display: block
    margin: 12px 0

.crossServerBlind-server
  position: relative
  padding-left: 24px
  margin: 12px 0
  min-height: 26px

  .icon
    position: absolute
    height: 18px
    width: 18px
    top: 2px
    left: 0

.crossServerBlind-server-title
  font-weight: 500
  line-height: 24px

.crossServerBlind-server-detail
  margin-left: 4px
  color: var(--text-heading2-subtitle)

.crossServerBlind-table
  margin-left: -32px
  margin-right: -32px

  .table-content
    background-color: var(--background-primary)

  .table-title
    background-color: var(--card-primary)

  .table-cell:first-child .table-cell-content
    padding-left: 32px

[dir = 'rtl']
  .crossServerBlind-server
    padding-right: 24px
    padding-left: unset
    svg
      left: unset
      right: 0

  .crossServerBlind-table
    .table-cell
      &:first-child
        .table-cell-content
          padding-left: 0
          padding-right: 32px
      &:last-child
        .table-cell-content
          padding-left: 0
          padding-right: 12px

.security-device-enrollment
  display: flex
  flex-direction: column
  flex: 100%
  justify-content: space-around
  padding: 16px 0

  .security-device-enrollment-description
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    flex: 2

    .security-device-enrollment-description-title
      font-size: 20px
      font-weight: 500
      line-height: 30px
      margin: 24px 0 7px 0
      text-transform: uppercase

    .security-device-enrollment-description-message
      font-size: 16px
      font-weight: 500
      line-height: 24px
      color: var(--text-heading2-subtitle)
      margin-bottom: 24px

      .security-device-enrollment-description-message-button
        text-align: center
        margin-top: 12px

  .security-device-enrollment-form
    display: flex
    flex: 1
    flex-wrap: wrap
    gap: 16px
    padding-top: 24px
    border-top: 2px solid var(--line-general)

    .form-field
      flex: 1 0 calc(50% - 8px)
      margin-top: 0!important

    .full-width
      flex: 1 0 100%

    .form-field--checkbox
      padding-top: 16px


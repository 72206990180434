.panelUsers-table
  .table-content
    margin-right: 16px
  &.bottom-page-scrolled
    height: calc(100% - 70px) !important
    .table-content
      padding-bottom: 0!important
  .table-cell
    &:last-child
      width: 46px !important

[dir = 'rtl']
  .panelUsers-table
    margin-left: 0 !important
    .table-content
      margin-left: 0
      margin-right: 0

.tooltipList
  min-width: 250px
  max-width: 300px
  max-height: 400px
  position: relative
  display: flex
  flex-direction: column
  flex-wrap: nowrap
  align-content: flex-start
  justify-content: flex-start
  align-items: flex-start
  text-align: start
  gap: 8px

  .tooltipList-heading
    color: var(--text-additional)
    text-transform: uppercase
    border-bottom: 1px solid var(--text-additional)
    opacity: 70%
    display: inline-block
    position: relative
    width: 100%
    padding: 0 0 8px 0

  .tooltipList-heading--error
    color: var(--color-error)
    opacity: 100%

  .tooltipList-heading--success
    color: var(--color-success)
    opacity: 100%

  .tooltipList-heading--warning
    color: var(--color-warning)
    opacity: 100%

  .tooltipList-heading--warning
    color: var(--color-primary)
    opacity: 100%

  .tooltipList-list
    width: 100%
    display: flex
    flex-direction: column
    flex-wrap: nowrap
    align-content: flex-start
    justify-content: flex-start
    align-items: flex-start
    gap: 8px
    overflow-x: scroll

    .tooltipList-list-item
      position: relative
      font-size: var(--font-size-primary)
      color: var(--text-additional)
      opacity: 70%
      display: flex
      flex-direction: row
      justify-content: flex-start
      align-items: flex-start
      gap: 8px
      overflow: hidden
      text-overflow: ellipsis
      width: 100%
      flex-shrink: 0

      .tooltipList-list-item-icon
        width: 24px
        height: 24px
        display: flex
        align-content: center
        justify-content: center
        align-items: center
        flex-shrink: 0

      .tooltipList-list-item-content
        line-height: 24px
        white-space: break-spaces
        width: calc(100% - 32px)

      .tooltipList-list-item-content--monospace
        font-family: monospace
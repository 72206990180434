.firmware
  > .bar
    flex-shrink: 0

  > .scroll
    margin-top: 8px
    flex-shrink: 1
    flex-basis: auto
    flex-grow: initial
    height: auto

  .table
    display: flex
    flex-direction: column
    align-items: center

    .table-title
      position: unset

    .title
      margin-bottom: 16px

  .table-content
    width: 100%
    max-width: 600px

  .progressHolder
    line-height: 30px
    font-size: var(--font-size-big)
    margin: 0 auto
    text-align: center

  .form-row
    padding: 0 16px

  .table-header
    margin-right: 12px
    padding: 0 16px
    flex-shrink: 0

  .table-header-cell
    width: 35%
    padding-left: 0
    padding-right: 0

    &:last-child
      width: 65%

  .bottom-page-scrolled
    .table-content
      &:last-child
        padding-bottom: 0  !important

.firmware__upgrade-iq-panels-table
  margin-top: 25px

.firmware-select
  width: 500px

.page-top > .firmware-search:first-child
  margin-left: 68px

.firmware-btn--goBack
  position: absolute
  top: 8px

.firmware-row
  height: 50px
  line-height: 50px
  font-size: var(--font-size-medium)
  font-weight: 500

[dir = 'rtl']
  .firmware
    .definition
      direction: rtl !important
      justify-content: flex-start

.panelFirmware
  padding-right: 16px

.applications-icon
  margin-right: 4px

.application
  font-weight: bold
  font-size: var(--font-size-small)
  margin: 0 2px
  opacity: var(--opacity-text-super-light)

.application--enabled
  opacity: 1

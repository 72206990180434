.panelStatus
  color: var(--text-heading2-subtitle)

.panelStatus-icon
  width: 24px
  height: 24px

  &.success
    animation: blink calc(var(--time) * 3) ease

.panelStatus-icon--offline
  color: var(--color-error)

.panelStatus-icon--online
  color: var(--color-success)

.panelStatus-icon--connected
  color: var(--color-info)

.panelStatus-icon--installed
  color: var(--text-heading2-subtitle)

.panelStatus-icon--unknown
  color: var(--icon-disabled)

@keyframes blink
  from, to
    transform: scale(1)
    text-shadow: none

  5%
    transform: scale(1.3)
    text-shadow: 0 0 10px currentColor

.panelStatusLine
  font-weight: 500
  height: 36px
  padding-left: 16px
  text-transform: uppercase
  margin-left: 16px
  border-left: 1px solid var(--line-general)
  display: flex
  flex-flow: column
  justify-content: center
  align-items: flex-start

.panelStatusLine--hasTroubles
  color: var(--color-error)

.panelStatusLine-message
  display: block
  margin-top: 2px
  font-size: var(--font-size-super-small)
  color: var(--icon-disabled)
  text-transform: initial

.panelStatusLine-discovery
  display: block
  margin-top: 2px
  font-size: var(--font-size-primary)
  color: var(--color-gray-600)
  text-transform: initial

.panelStatusLine-discovery-progresstext,
.panelStatusLine-discovery-duration
  color: var(--color-gray-600)
  font-size: var(--font-size-super-small)
  vertical-align: middle

.panelStatusLine .panelStatusLine-discovery-progressbar
  vertical-align: middle
  width: 70px

[dir = 'rtl']
  .panels-table-faults-cell-dropDown-container
    .definition
      direction: rtl !important
    .severity-list
      .worryDefinition
        padding-left: 0
        padding-right: 28px
        .worry
          left: unset
          right: 0

  .panels-table-app-cell-dropDown-container
    .dropDown-content
      .toggle
        margin-right: unset
        margin-left: 25px

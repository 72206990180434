.batchInfo-list-item
  cursor: pointer
  padding: 10px 12px
  transition: background-color var(--ease-md) var(--time)
  display: flex
  flex-direction: column
  align-content: space-between
  align-items: flex-start
  width: 100%
  row-gap: 7px
  border: 1px solid var(--line-general)
  background-color: var(--background-menu-dropdown)
  box-shadow: var(--box-shadow-dropdown)
  border-radius: 2px
  position: relative

  &:hover
    background-color: var(--background-app-bar)
    box-shadow: var(--box-shadow-app-asside)
    transition: 200ms

    &:has(.batchInfo-list-item-stopProcess)
      .batchInfo-list-item-details-duration
        display: none

    .btn.batchInfo-list-item-stopProcess
      display: initial
      box-shadow: none!important

  .processError-info
    display: flex
    flex-direction: column
    gap: 12px
    width: 100%

    .processRunnerError-info-content
      display: flex
      flex-direction: row
      gap: 8px

      svg
        width: 20px
        min-width: 20px
        height: 20px
        min-height: 20px
        color: var(--color-error)

      .content
        color: var(--color-error)
        padding: 0 30px 0 0
        flex-direction: column
        justify-content: flex-start
        line-height: 20px
        font-size: 12px
        gap: 5px

        p
          word-break: break-all
          max-width: 100%
          max-height: 200px
          overflow: auto

        h5
          font-weight: 500

[dir='rtl']
  .batchInfo-list-item
    .processError-info
      .processRunnerError-info-content
        .content
          padding: 0 0 0 30px

.batchInfo-list-item-details
  display: flex
  width: 100%

=overflowTooltipStyle
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis

.batchInfo-list-item-details-panel
  width: 35%
  font-weight: 500
  +overflowTooltipStyle

.batchInfo-list-item-details-duration
  width: 65%
  text-align: right
  color: var(--text-heading2-subtitle)
  font-size: 12px
  +overflowTooltipStyle

.batchInfo-list-item-status
  display: flex
  align-items: baseline
  width: 100%
  flex-direction: column
  justify-content: flex-start
  row-gap: 12px

  .definition
    font-weight: 400
    font-size: 12px
    width: calc(100% - 25px)

    svg
      width: 20px
      height: 20px

    &.danger
      color: var(--color-error)
      align-items: center
      line-height: initial

      svg
        *
          fill: var(--color-error)

    &.success
      color: var(--color-success)
      svg
        *
          fill: var(--color-success)

    &.started
      color: var(--color-info)
      svg
        *
          fill: var(--color-info)

    &.running
      color: var(--color-warning)
      svg
        *
          fill: var(--color-warning)

      + .progress .progress-bar
        background-color: var(--color-warning)

  .progress
    width: 100%
    height: 4px
    border-radius: 4px

    &.batch-runner--failed
      .progress-bar
        background-color: var(--color-error)

    &.batch-runner--not-started
      .progress-bar
        background-color: var(--icon-primary)

    &.batch-runner--started
      .progress-bar
        background-color: var(--color-info)

    &.batch-runner--handled:after
      background-color: var(--color-warning) !important

    &.batch-runner--handled:before
      background-color: var(--color-warning) !important

    .progress-value
      display: none

  > .btn
    position: absolute
    right: 12px
    bottom: 21px
    top: 32px

.btn.batchInfo-list-item-retryProcess
  &:active, &:focus
    &:before
      opacity: 0
  .btn-icon
    color: var(--icon-default)

    &:hover
      transition: 200ms
      color: var(--text-heading1)

.btn.batchInfo-list-item-stopProcess
  width: 20px
  height: 20px
  display: none
  position: absolute
  right: 14px
  top: 30px
  background: none
  border: none
  line-height: 14px

  &:active
    background: none!important
    box-shadow: none!important
    color: initial

  .btn-content
    line-height: 14px

  .btn-icon
    height: 16px
    width: 16px
    color: var(--icon-default)

    &:hover
      transition: 200ms
      color: var(--text-heading1)

[dir = 'rtl']
  .definition + .runners-table-process-cell
    direction: ltr !important

  .batches-table-status-cell
    position: relative
    padding-right: 24px
    svg
      position: absolute
      right: 0

[dir="rtl"]
  .batchInfo-list-item-status
    align-items: flex-start
    .definition
      direction: rtl !important
      svg
        margin-right: 6px

    > .btn
      left: 12px
      right: auto

  .batchInfo-list-item-details-duration
    text-align: left

  .btn.batchInfo-list-item-stopProcess
    right: unset
    left: 14px

body:has(.userInfo-menu)
  .userInfo
    .ava
      border: 1px solid white

  &.theme--sanMarino
    .userInfo
      .ava
        border: 1px solid var(--text-heading2-subtitle)

.userInfo
  z-index: 6

  .ava
    cursor: pointer

.userInfo-dropDown-container
  top: 78px !important
  width: 317px
  z-index: 50

  .slideDown--popdown
    background-color: var(--background-menu-dropdown)
    overflow: hidden

  .card
    background-color: var(--background-menu-dropdown)
    padding: 0 12px
    overflow: hidden

.userInfo-menu
  padding: 20px 0 24px 0
  border-top: 1px solid var(--line-general)
  display: flex
  flex-direction: column
  justify-content: space-around
  align-items: flex-start
  row-gap: 4px

  .menu-item-icon
    width: 20px
    height: 20px

  .menu-item
    font-size: var(--font-size-medium)
    color: var(--text-heading1)
    padding: 0 12px
    display: flex
    flex-direction: row
    align-content: center
    align-items: center
    width: 100%
    height: 44px

    svg
      color: var(--icon-primary)

    &:last-child
      border-radius: 0 0 2px 2px

  .menu-item--hover
    background-color: var(--background-success)
    border-radius: 2px

    svg
      color: var(--text-heading1)

.userInfo-user
  width: 100%
  display: flex
  flex-direction: column
  align-content: space-around
  justify-content: center
  align-items: center
  text-align: center
  row-gap: 16px
  padding: 24px 0 20px !important

  .userInfo-main-info
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: center
    row-gap: 10px
    width: 100%

  .userInfo-role
    background-color: var(--background-partition-label)
    border-radius: 2px
    padding: 2px 5px
    color: var(--color-warning)
    max-width: 100%
    text-overflow: ellipsis
    overflow: hidden

.userInfo-name
  font-weight: 500
  font-size: var(--font-size-medium)
  max-width: 100%
  text-overflow: ellipsis
  overflow: hidden

.userInfo-details
  opacity: var(--opacity-text-secondary)
  max-width: 100%
  overflow: hidden

  .tooltip
    text-overflow: ellipsis
    overflow: hidden

.userInfo-ava
  width: 60px
  height: 60px
  font-size: 16px
  font-weight: 500
  display: flex
  flex-direction: row
  align-content: center
  justify-content: center
  align-items: center
  border: none !important

.edit-user-profile-modal
  .disclaimer
    margin-top: 16px

.userInfo-help
  color: var(--color-primary)
  font-size: var(--font-size-small)
  text-decoration: underline

[dir = 'rtl']
  .userInfo-main-info
    .userInfo-details
      direction: ltr

  .edit-user-profile-modal
    .input-field
      direction: ltr
      text-align: end


.userCell
  text-align: left
  padding: 8px 16px

  .list-item
    margin-top: 8px

    svg
      color: var(--background-success)

.user-info-full
  display: flex
  gap: 9px
  flex-direction: row
  flex-wrap: nowrap
  align-items: center
  justify-content: flex-start

  > .ava
    flex-shrink: 0
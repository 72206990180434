.central-stations
  margin-right: 16px

.group-central-stations
  display: grid
  grid-template-columns: 1fr 1fr
  grid-gap: 16px

  .page-card
    margin: 0

@media screen and (max-width: 1000px)
  .central-stations
    grid-template-columns: 1fr

.central-station-links
  .empty
    display: block
    margin: 16px 0 12px

.central-station-link
  font-weight: 500
  margin-right: 16px
  display: inline-block
  padding: 0 12px
  margin-top: 12px
  height: 30px
  line-height: 30px
  border-radius: 15px
  font-size: var(--font-size-small)
  text-transform: uppercase
  background-color: var(--background-partition-label)
  white-space: nowrap

.central-edit-link
  cursor: pointer

.trigger
  font-weight: bold
  font-size: var(--font-size-small)
  margin: 0 2px
  cursor: pointer

.trigger--enabled
  color: #000000

.trigger--disabled
  color: var(--icon-disabled)

.connection-type-host-wrapper
  position: relative
  margin-bottom: 16px
  .input-lock-icon
    position: absolute
    top: 21px
    left: 3px
  .form-field
    margin-top: 16px
  .input--label
    margin-top: 16px

.connection-type-host
  position: relative
  .form-field-error
    margin-top: 33px
  .input-field
    position: absolute
    padding-left: 25px
  .input--label
    &::before
      display: inline-block
      content: ' '
      width: 20px
      height: 20px
      position: absolute
      left: 0
  .form-field-label
    margin-left: 25px
  .input--focus
    .form-field-label
      margin-left: 0
  .input--dirty
    .form-field-label
      margin-left: 0

.connection-type-host-wrapper > .connection-type-host
  margin-bottom: 41px

.connection-type-host.form-field--error
    margin-bottom: 1px

.central-stations-edit-links-header
  text-transform: none


[dir = 'rtl']
  .group-central-stations
    .card-header
      .definition
        direction: rtl !important
        justify-content: flex-start
  .central-stations-edit-links-form
    .definition
      direction: rtl !important
      justify-content: flex-start
  .central-stations-table-definition-icon
    position: relative
    padding-right: 24px
    svg
      position: absolute
      right: 0
      margin-right: 0

.modal-table
  box-shadow: none
  border-radius: 0
  margin-bottom: 16px
  border-top: 1px solid rgba(0, 0, 0, .1)
  border-bottom: 1px solid rgba(0, 0, 0, .1)

  &.table--fullHeight
    max-height: calc(100vh - 240px)

  .table-content
    box-shadow: none

  .table-cell:first-child .table-cell-content
    padding-left: 32px

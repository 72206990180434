.editSettingsCard
  width: 656px!important

  .tabs.tabs--noTabLabel
    margin-bottom: 0
    > .tab
      padding: 18px

  .tabs-layout
    min-height: 591px

.editSettings-layout
  display: flex
  flex-direction: column

  .card-content
    flex-grow: 1

.menuSetting
  .col
    h3:first-child
      margin-top: 0
    .scroll
      max-height: 397px
    .scroll:last-child
      margin-bottom: 0

  .radiobox-label
    .tooltip
      width: 12px
      height: 12px
      margin-left: 6px

      svg
        width: 12px
        height: 12px
        color: var(--background-tooltip)
        background-color: var(--color-info)
        border-radius: 50%

.theme--sanMarino .radiobox-label .tooltip svg
  color: var(--card-primary)

.event-deliverance
  display: flex
  justify-content: flex-end
  flex-flow: row
  align-items: center

  .chips-item
    padding: 3px 4px
    margin-bottom: 0
    font-size: 12px
    line-height: 12px
    font-weight: 400
    min-width: 34px
    height: 18px
    background-color: var(--background-partition-label)
    color: var(--icon-primary)
    display: inline-flex
    flex-wrap: nowrap
    align-content: space-around
    justify-content: space-around

    svg
      height: 12px
      width: 12px

.eventDeliveryStatus-tooltip
  width: 300px
  text-align: left
  position: relative
  max-width: 300px
  display: block
  padding-right: 15px

  h5.deliverance-heading
    color: var(--text-heading2-subtitle)
    text-transform: uppercase
    border-bottom: 1px solid var(--line-general)
    display: inline-block
    position: relative
    width: 100%
    padding-bottom: 5px
    margin-bottom: 6px

  .deliverance-tooltip-list
    width: 100%

    .deliverance-tooltip-list-item
      position: relative
      font-size: 14px
      color: var(--text-heading1)
      font-weight: 400
      width: 100%
      margin-top: 6px
      margin-bottom: 6px
      display: flex
      flex-direction: row
      justify-content: space-between
      align-items: center
      white-space: nowrap
      height: 18px

      .list-item-divider
        background-color: var(--line-general)
        width: 100%
        height: 1px
        margin-left: 10px
        margin-right: 10px

  .chips-item
    height: 18px
    min-width: 34px
    font-size: 12px
    line-height: 12px
    font-weight: 400
    padding: 2px 6px
    background-color: var(--line-general)
    color: var(--icon-primary)
    display: inline-flex
    flex-direction: row
    flex-wrap: nowrap
    justify-content: space-around
    align-content: space-between
    align-items: center
    gap: 2px

    svg
      min-height: 12px
      min-width: 12px
      height: 12px
      width: 12px

.chips-item

  &.eventDeliveryStatus-queued
    color: var(--icon-primary)

  &.eventDeliveryStatus-processing
    color: var(--color-info)

  &.eventDeliveryStatus-delivered
    color: var(--color-success)

  &.eventDeliveryStatus-retry
    color: var(--color-warning)

  &.eventDeliveryStatus-failed
    color: var(--color-error)

.event-linechart
  width: 100%
  display: flex
  justify-content: space-around
  flex-flow: row wrap
  align-items: stretch
  margin-top: 15px

  > .status-line
    height: 4px

    &.queued
      background-color: var(--icon-primary)

    &.processing
      background-color: var(--color-info)

    &.delivered
      background-color: var(--color-success)

    &.retry
      background-color: var(--color-warning)

    &.event-failed
      background-color: var(--color-error)

.eventBlindModal
  .card-header
    background-color: var(--background-success)
    border-bottom: 1px solid var(--line-general)

  .card-content
    padding-left: 0
    padding-right: 0

    > .device
      border: 2px solid var(--line-general)
      box-sizing: border-box
      border-radius: 2px 2px 0 0
      margin-left: 32px
      margin-right: 32px

  .event-general-info
    border: 2px solid var(--line-general)
    box-sizing: border-box
    border-radius: 2px 2px 0 0
    padding: 16px
    margin-top: 24px
    display: flex
    flex-direction: row
    flex-wrap: wrap
    align-items: center
    align-content: space-between
    row-gap: 12px
    margin-bottom: 24px
    margin-left: 32px
    margin-right: 32px

    > .event-general-info-block
      font-size: 14px
      line-height: 16px
      width: 50%

      > svg
        height: 14px

      > .appointment
        justify-content: flex-end
        white-space: nowrap

    .event-panel span
      font-size: 12px
      line-height: 14px
      opacity: 0.4
      display: inline-block
      margin-top: 1px
      vertical-align: top

    .event-date
      font-size: 12px
      line-height: 14px
      display: flex
      flex-direction: row-reverse

    .event-appointment
      text-align: right

  .card-header
    padding: 16px 32px

    .card-close
      right: 25px
      top: 16px

.sidebar-content
  padding: 16px 0

  h3.deliverance-heading
    font-weight: 500
    font-size: 12px
    line-height: 14px
    color: var(--text-heading1)
    margin-bottom: 10px
    text-transform: uppercase
    margin-left: 32px

  .event-no-cs-wrapper
    display: flex
    flex-wrap: nowrap
    flex-direction: column
    justify-content: center
    align-items: center

    .event-no-cs
      margin-top: 50%
      text-align: center
      width: 200px

      > h4.deliverance-heading
        color: var(--text-heading1)
        font-size: 20px
        line-height: 30px
        text-transform: uppercase
        opacity: 0.5

      > span
        color: var(--text-heading1)
        opacity: 0.5

      .definition
        display: block

        > svg
          width: 60px
          height: 35px


  .centralstations-events
    margin-top: 24px

    .deliverance-centralstation-table
      margin-bottom: 16px

      h3.deliverance-heading
        color: var(--text-heading1)
        text-transform: uppercase
        font-size: 12px
        margin-bottom: 10px
        margin-top: 16px
        line-height: 14px
        display: inline-block

      .deliverance-centralstation-table-content
        display: table
        width: 100%

        .tooltip
          svg
            width: 16px
            height: 16px

        .row
          display: table-row
          border-bottom: 1px solid var(--line-table)

          &.header-row
            background-color: var(--background-success)

            .col
              color: var(--text-heading2-subtitle)
              text-transform: uppercase
              font-size: 12px
              line-height: 14px
              border-bottom: none

          .col
            display: table-cell
            color: var(--text-heading1)
            font-size: 14px
            line-height: 16px
            padding-left: 16px
            padding-top: 8px
            padding-bottom: 8px
            border-bottom: 1px solid var(--line-table)

            &:first-child
              width: 64px
              padding-left: 32px

            &:last-child
              padding-right: 32px
              width: 70px


  .deliverance-line-chart
    border: 2px solid var(--line-general)
    box-sizing: border-box
    padding: 0 16px
    margin-left: 32px
    margin-right: 32px

    .overall-deliverance
      display: flex
      flex-direction: row
      justify-content: flex-start

      > .deliverance-status-counter
        width: 20%
        font-size: 12px
        color: var(--text-heading2-subtitle)
        text-transform: uppercase

        &.queued span
          color: var(--icon-primary)

        &.processing span
          color: var(--color-info)

        &.delivered span
          color: var(--color-success)

        &.retry span
          color: var(--color-warning)

        &.event-failed span
          color: var(--color-error)

        > span
          display: block
          width: 100%
          margin-top: 5px
          font-size: 14px

.theme--sanMarino
  .chips-item
    &.eventDeliveryStatus-queued
      color: var(--icon-primary)

      > svg path
        fill: var(--icon-primary)

  .eventDelivery-tooltip
    opacity: 0.95

    .tooltip-arrow
      border-bottom-color: var(--card-primary)
      z-index: 999

    .tooltip-bubble
      background-color: var(--card-primary)
      box-shadow: var(--box-shadow-elevation-2)
      backdrop-filter: blur(5px)

  .eventBlindModal
    .card-header
      .btns
        background-color: var(--card-primary)

    .device
      box-shadow: none

.appointment
  display: flex
  flex-direction: row
  gap: 4px

  > .appointment-text
    overflow: hidden
    text-overflow: ellipsis

.eventDelivery-tooltip
  z-index: 39

.tooltip--inline
  width: max-content

.deliverance-partitions
  padding-left: 7px
  font-weight: 400
  font-size: 12px
  line-height: 14.06px
  color: var(--text-heading2-subtitle)

.event-centralstation-status
  font-size: 14px
  font-weight: 400
  line-height: 16px
  letter-spacing: 0
  text-align: left

.event-centralstation-status-online
  color: var(--color-success) !important

.event-centralstation-status-offline
  color: var(--text-heading2-subtitle) !important

.event-centralstation-status-heartbeat
  color: var(--color-info) !important

.event-centralstation-status-notapplicable
  color: var(--icon-primary) !important

.deliverance-centralstation-protocol
  width: 131px
  padding-right: 20px
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap

.deliverance-centralstation-host
  width: 144px
  padding-right: 20px
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap

.deliverance-centralstation-icon-tooltip
  display: flex
  justify-content: center

.deliverance-centralstation-icon-retry
  color: var(--color-warning)

[dir = 'rtl']
  .deliverance-heading
    margin-left: unset
    margin-right: 32px
    text-align: right

  .eventDeliveryStatus-tooltip
    padding-right: unset
    padding-left: 15px

  .deliverance-centralstation-table
    .row
      .col
        &:first-child
          padding-right: 32px
          padding-left: unset

  .event-general-info
    .event-panel
      direction: initial
      display: flex
      justify-content: flex-end
      position: relative
      padding-right: 24px

      svg
        position: absolute
        right: 0

      span
        margin-left: 4px

  .severity
    .severity-icon
      margin-right: unset
      margin-left: 4px

.streamNewEvents
  padding: 0

  .btns
    .stream-events-btn
      line-height: initial

  .stream-events-btn
    box-shadow: none !important

    &:active
      border-style: none

    &.active-stream
      color: var(--color-primary)

      svg
        color: var(--color-primary)

[dir = 'rtl']
  .event-centralstation-status
    display: flex !important
    justify-content: flex-start
    margin-right: 0 !important


.header
  z-index: 5
  position: relative
  padding: 1px 16px 0
  height: 100px

.header-top
  height: 46px
  padding-right: 44px

.header-bottom
  height: 54px

  .tabs
    height: 100%
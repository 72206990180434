.thermostatModeChoose
  .form-field-label
    color: var(--text-heading2-subtitle)
    text-transform: uppercase
    font-weight: 500
    margin-bottom: 10px

.thermostatModeChoose-item-container
  background-color: var(--button-light)
  box-shadow: var(--box-shadow-elevation-05)
  border-radius: 2px
  padding: 2px
  display: flex
  position: relative

.thermostatModeChoose-item-container--hidden
  visibility: hidden

.thermostatModeChoose-item-container--off
  .thermostatModeChoose-item--active
    >svg
      color: var(--color-error)

.thermostatModeChoose-item-container--heat
  .thermostatModeChoose-item-pad
    background-color: var(--color-warning)

.thermostatModeChoose-item-container--cool
  .thermostatModeChoose-item-pad
    background-color: var(--color-info)

.thermostatModeChoose-item-container--auto
  .thermostatModeChoose-item-pad
    background-color: var(--color-success)

.thermostatModeChoose-item-container--auxHeat
  .thermostatModeChoose-item-pad
    background-color: var(--color-error)

.thermostatModeChoose-item
  flex-basis: 98px
  flex-grow: 1
  width: 98px
  height: 34px
  color: var(--text-heading2-subtitle)
  position: relative
  z-index: 2
  display: flex
  align-items: center
  justify-content: center
  cursor: pointer

  >svg
    margin-right: 12px

.thermostatModeChoose-item--active
  color: var(--text-additional)

.thermostatModeChoose-item-pad
  border-radius: 2px
  position: absolute
  z-index: 1
  height: calc(100% - 4px) // container padding
  transition: width ease-in-out  var(--time), transform ease-in-out  var(--time), background-color ease-in-out  var(--time)

[dir = 'rtl']
  .thermostatModeChoose-item-container
    .thermostatModeChoose-item
      svg
        margin-right: unset
        margin-left: 12px


.dropDown-container
  position: fixed
.nav
  font-size: var(--font-size-medium)
  user-select: none
  height: 100%
  background: var(--card-primary)
  box-shadow: var(--box-shadow-app-asside)

  .menu-wrapper
    height: 100%
    display: flex
    flex-direction: column
    justify-content: space-between
    padding: 12px 0

    > .main-links
      .nav-link-wrapper:last-child
        .nav-link-icon
          height: 20px
          background: none
      & > .nav-link-wrapper > a.nav-link
        padding-left: 5px
        padding-right: 5px
        > .nav-link-content
          width: 100%
          hyphens: auto
          -moz-hyphens: auto
          -webkit-hyphens: auto
          -ms-hyphens: auto
          word-break: break-word

    .bottom-links
      border-top: 1px solid var(--line-general)
      padding-top: 12px

.navbar-top
  height: 68px
  width: calc(100% - 100px)
  max-width: calc(100% - 100px)
  position: fixed
  background-color: var(--background-app-bar)
  box-shadow: var(--box-shadow-top-bar)
  display: flex
  align-content: center
  flex-direction: row
  align-items: center
  justify-content: space-between
  padding-right: 16px
  z-index: 5

  .right-menu
    display: flex
    flex-direction: row
    align-content: center
    flex-wrap: nowrap
    align-items: center
    justify-content: flex-end

    .userInfo
      display: flex
      align-items: center

    .ava
      width: 40px
      height: 40px
      display: flex
      justify-content: center
      align-items: center

    > *
      margin-left: 24px!important
      margin-right: 0!important
      position: relative
      height: 64px
      &::before
        content: ''
        width: 1px
        height: 64px
        display: block
        background-color: var(--line-general)
        position: absolute
        left: -12px
        top: 0

  .sidebar-opener
    width: 60px
    height: 60px
    display: flex
    color: var(--icon-primary)
    border-radius: 2px
    margin: 4px 12px 4px 12px
    align-content: center
    flex-direction: row
    justify-content: center
    align-items: center

    &:hover, &.active
      background-color: var(--table-hover)
      transition: background-color  var(--time) var(--ease-out-sine), color  var(--time) var(--ease-out-sine)
      cursor: pointer
      svg
        color: var(--text-heading1)!important

    &.active
      color: var(--color-primary)


.nav-link-wrapper
  position: relative
  margin: 4px
  border-radius: 2px

.theme--sanMarino
  .nav-link--active
    background-color: rgba(black, 0.07)!important
  .nav-link:hover
    background-color: var(--table-hover)

  .nav-link:has(.nav-link-expand--active)
    background-color: rgba(black, 0.07)!important

.theme--valencia
  .nav-link--active
    background-color: var(--line-general)!important
  .nav-submenu
    .nav-link-wrapper
      .nav-link--active
        background-color: var(--background-menu-dropdown) !important
        &:hover
          background-color: var(--background-menu-dropdown) !important

  .nav-link:has(.nav-link-expand--active)
    background-color: var(--line-general)!important

.nav-link
  cursor: pointer
  position: relative
  transition: background-color  var(--time) var(--ease-out-sine), color  var(--time) var(--ease-out-sine)
  display: flex
  flex-direction: column
  justify-content: flex-start
  align-items: center
  row-gap: 10px
  padding: 10px 0

  &:has(.nav-link-expand--active)
    background-color: rgba(white, 0.7)
    .nav-link-content
      color: var(--color-primary)
      font-weight: 700

    &:hover
      svg
        color: var(--color-primary)!important

  &:hover
    background-color: var(--background-success)
    color: var(--text-heading1)!important

    > svg
      color: var(--text-heading1)!important

  > svg
    color: var(--icon-primary)
    height: 20px

  &.nav-link--active
    color: var(--color-primary)

    &:hover
      > svg
        color: var(--color-primary)!important

    > svg
      color: var(--color-primary)

    .nav-link-filter
      font-weight: 400
      background-color: var(--background-partition-label)
      color: var(--text-heading1)
      > svg
        color: var(--text-heading1)

.nav-link--active, .nav-link-expand--active + svg, .nav-link-expand--active + .nav-link-content
  color: var(--color-primary)!important
  font-weight: 700

  &, &:hover
    background-color: rgba(black, 0.07)
    color: var(--color-primary)!important

  .nav-link-icon
    opacity: 1

.nav-link-icon
  width: 20px
  transition: opacity  var(--time)var(--ease)

.nav-link-action
  position: absolute
  transform: translateY(-50%)
  padding: 8px
  top: 50%
  right: 9px
  cursor: pointer
  color: var(--icon-default)
  transition: color var(--time) var(--ease)

.nav-link-action-icon
  display: block
  width: 10px

.nav-link-wrapper--action
  .nav-link-content
    margin-right: 20px

.nav-link-wrapper--action:hover
  .nav-link-action
    color: var(--icon-hover)

.nav-link-content
  overflow: hidden
  text-overflow: ellipsis
  text-align: center
  font-size: var(--font-size-small)
  .tooltip
    text-overflow: ellipsis
    overflow: hidden

.nav-submenu
  position: absolute
  min-width: 227px
  font-size: var(--font-size-primary)
  bottom: -150%
  left: 112px
  box-shadow: var(--box-shadow-submenu)
  background-color: var(--background-menu-dropdown)
  padding: 6px 0

  .filter-submenu
    left: 220px
    z-index: 1

  .nav-link-filter
    position: absolute
    right: 8px
    top: 8px

    span
      display: none
    > svg
      margin-left: 0!important

  .nav-link
    display: flex
    background-color: var(--background-menu-dropdown)!important
    border-radius: 2px
    color: var(--text-heading1)
    flex-direction: row
    justify-content: flex-start
    font-weight: 500
    align-items: center
    padding-left: 16px
    margin-right: -4px
    margin-left: -4px


    .nav-link-content
      font-size: var(--font-size-primary)
      text-align: left
    > svg
      color: var(--icon-primary)


    &:hover
      color: var(--text-heading1)!important
      background-color: var(--table-hover)!important

      > svg
        color: var(--text-heading1)

    .nav-link-icon
      margin-right: 16px

  .nav-link-wrapper
    .nav-link--active
        color: var(--color-primary)!important

        > svg
          color: var(--color-primary)
        &:hover
          background-color: var(--background-menu-dropdown)!important
          > svg
            color: var(--color-primary)

.theme--dark
  .nav-submenu
    .nav-link
      &:hover
        background-color: var(--background-app-bar)!important

.nav-link-expand
  display: none

  &:hover
    background-color: rgba(0, 0, 0, .08)

.nav-link-expand--active .nav-link-expand-icon
  transform: rotate(0deg)

.nav-link-expand-icon
  transition: transform  calc(var(--time) / 2) var(--ease-out-cubic)
  transform: rotate(-90deg)

.nav-link

  .nav-link-coin
    background-color: var(--button-light)
    border: 0
    border-radius: 12px
    height: 24px
    line-height: 24px
    margin: 2px 0
    min-width: 24px
    text-align: center
    white-space: nowrap
    max-width: 100%
    width: auto
    position: absolute
    top: 4px
    right: 8px
    cursor: pointer
    font-size: var(--font-size-small)
    font-weight: bold
    color: var(--text-heading2-subtitle)

    ~ .nav-link-content
      margin-right: 30px

    .nav-link-coin-icon
      width: 11px

    &.nav-link-coin--alarm
      background-color: var(--color-error)
      color: var(--text-additional)

  &:hover
    .nav-link-filter
      color: var(--text-heading1)
      background-color: var(--background-partition-label)
      > svg
        color: var(--text-heading1)

  .nav-link-filter
    text-transform: uppercase
    font-size: 10px
    line-height: 12px
    font-weight: 400
    text-align: center
    vertical-align: middle
    background-color: var(--background-success)
    border-radius: 100px
    padding: 7px 10px
    color: var(--text-heading2-subtitle)

    > svg
      margin-left: 4px
      margin-top: -2px
      color: var(--text-heading2-subtitle)

    svg:first-child
      margin-left: 0

  > .nav-link--active
    .nav-link-filter
      color: var(--primary)
      > svg
        color: var(--primary)

.nav-link
  + .nav-submenu
    .nav-link-coin
      top: 7px
      right: 48px

  .nav-link-filter
    font-weight: 400
    color: var(--text-heading1)
    background-color: var(--background-partition-label)
    > svg
      color: var(--text-heading1)

    &:hover
      background-color: var(--table-active)
      color: var(--color-primary)
      > svg
        color: var(--color-primary)

    &.nav-link-filter--active
      font-weight: 500
      background-color: var(--table-active)
      color: var(--color-primary)!important
      border: 1px solid var(--color-primary)
      margin: -1px 0

      > svg
        color: var(--color-primary)!important

.filter-submenu
  top: 0
  overflow-x: hidden
  height: fit-content
  max-height: 350px
  scrollbar-gutter: both-edges
  padding: 6px 0

  .nav-link
    .nav-link-content
      margin-right: 30px
      text-align: left

.filter-submenu-bottom-offset
  max-height: 350px
  height: fit-content
  padding-bottom: 7px
  top: unset
  bottom: -10px

@media (max-height: 700px)
  .main-links > .nav-link-wrapper:has(.nav-submenu:not(.filter-submenu))
    position: initial
    .nav-submenu
      bottom: 15px

.theme--sanMarino
  .nav-link
    .nav-link-filter--active
      background-color: var(--table-hover-table)
    .nav-link-filter:hover
      background-color: var(--table-hover-table)
      color: var(--color-primary)
      > svg
        color: var(--color-primary)

[dir="rtl"]
  .nav-link
    direction: rtl

    .nav-link-coin
      right: unset
      left: 8px

    .nav-link-filter > svg
      margin-left: unset
      margin-right: 4px

  .nav-submenu
    left: unset
    right: 96px

    .filter-submenu
      right: 220px

    .nav-link
      padding-left: unset
      padding-right: 12px

      .nav-link-icon
        margin-right: unset
        margin-left: 16px

      .nav-link-filter > svg
        margin-right: 0

    .nav-link-action
      right: unset
      left: 9px

    .nav-link-filter
      right: unset
      left: 8px

  .filter-submenu
    .nav-link
      padding-left: unset
      padding-right: 12px

      .nav-link-content
        margin-right: unset
        margin-left: 30px
        text-align: right

  .navbar-top .right-menu > *
    margin-right: 24px !important
    margin-left: 0 !important

  .navbar-top .right-menu
    margin-left: 12px
    & > *::before
      left: unset
      right: -12px

  .nav-link + .nav-submenu .nav-link-coin
    right: unset
    left: 48px

  .dropDown-container.nav-link-coin-dropDown-container:has(.slideDown--active)
    left: unset!important
    right: 50px

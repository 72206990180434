

.dashboard
  flex-wrap: wrap
  padding-right: 24px
  align-content: stretch
  justify-content: space-between

  .card
    position: relative
    padding: 16px

.dashboard-chartTitle
  font-weight: 500
  font-size: var(--font-size-small)
  height: 18px
  position: absolute
  left: 12px
  margin-bottom: 6px
  bottom: 100%
  text-align: center
  text-transform: uppercase
  color: var(--text-heading2-subtitle)

.dashboard-main
  margin-top: 22px
  width: 100%
  height: calc(65% - 60px - 48px - 24px) // minus bar and margins
  margin-bottom: 48px

.dashboard-secondary
  height: 35%
  width: calc(33.3% - 24px * 2 / 3)
  margin-bottom: 24px

[dir = 'rtl'] .dashboard
  padding-right: unset
  padding-left: 24px

  .dashboard-chartTitle
    left: unset

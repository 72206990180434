.add-camera-form
  width: 594px !important
  min-height: 600px
  display: flex
  flex-direction: column

  .form-content
    min-height: 600px

    .card-content
      padding-bottom: 70px

  .tab--active
    &::after
      background-color: initial

  .tabs-layout
    height: 100%

    .tabs
      width: 56px
      margin-bottom: 0

      .tab
        padding: 18px

  .add-camera-tab
    position: relative
    height: 100%

    .card-actions
      position: absolute
      right: 0
      bottom: 0
      padding-right: 32px

  .add-camera-form--section
    padding-bottom: 10px

    .device-form-content
      display: flex
      flex-direction: row
      flex-wrap: wrap
      row-gap: 10px
      column-gap: 16px
      justify-content: space-between
      align-content: flex-start

      input[name="password"]
        padding-right: 24px

      > .form-field
        width: calc((100% - 16px) / 2)
        margin-top: 0
        min-height: 62px

        .form-field-error
          max-width: 229px

        &.full-width
          width: 100%

      > .form-field:has(.dropDown-trigger)
        height: 100%

        .dropDown-trigger
          height: 100%

.add-camera-form--password-section
  position: relative

.add-camera-form--password
  position: absolute
  top: 24px
  right: 0
  &:hover
    cursor: pointer

.add-camera-form--partition-col
  position: relative
  .multiSelect
    .form-field-label
      padding-top: 3px
  .partitions-icon
    position: absolute
    left: 0
    top: 24px

  .dropDown-trigger:has(.form-field-error)
    .form-field-label
      color: var(--color-error)


[dir = 'rtl']
  .devices-add-camera-button
    .btn-content
      &:after
        content: ""
        position: absolute
        right: 0
        top: 0
        height: 100%
        width: 1px
        background-color: var(--line-table)

  .devices-bar-buttons
    .btn:last-child
      .btn-content
        padding-right: 12px
    &:has(.devices-add-camera-button)
      .btn:last-child
        .btn-content
          padding-right: 0
        &:after
          display: none

  .device-form-content
    .icon-left, .multiSelect
      svg
        left: unset
        right: 0

    input[name="password"]
      padding-left: 24px

    .input--dirty, .input--focus
      .form-field-label
        padding-right: 0

    .form-field-label, .input-field
      padding-right: 24px
      padding-left: 0

  .password-toggle-icon
    left: 0 !important
    right: unset !important

  .card-actions
    left: 0
    right: unset
    padding-left: 32px

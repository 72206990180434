.add-device-form
  width: 650px !important
  min-height: 600px

  .form-content
    min-height: 600px

    .card-content
      min-height: 533px
      padding-bottom: 70px
      display: flex
      flex-direction: column

      .spinner-container
        flex: unset

  .tab--active
    &::after
      background-color: initial

  .tabs-layout
    height: 100%

    .tabs
      width: 56px
      margin-bottom: 0

      .tab
        padding: 18px

        svg
          *
            fill-opacity: 1

        &:nth-child(3)
          padding-left: 16px
          .tab-icon
            width: 22px

  .add-device-tab
    position: relative
    height: 100%

    .card-actions
      width: 100%
      position: absolute
      right: 0
      bottom: 0
      padding-right: 32px

.add-device-autolearn-tab
  .card-content
    position: relative
    align-items: center
    justify-content: center

    .autolearn-in-process
      display: flex
      flex-direction: column
      align-items: center

      .autolearn-description-content
        display: flex
        flex-direction: column
        align-items: center

      .autolearn-description-content-wrapper
        display: flex
        flex-direction: column
        align-items: center

    .autolearn-with-table
      display: flex
      flex-direction: row
      align-items: center
      margin-bottom: 20px
      width: 530px
      padding: 10px 16px
      border: 1px solid var(--color-primary)
      border-radius: 2px
      justify-content: space-between

      .autolearn-description-content
        display: flex

      .autolearn-description-content-wrapper
        display: flex
        flex-direction: column
        align-items: start

      svg
        width: 36px
        height: 44px
        margin-right: 12px
        color: var(--color-primary)

      .autolearn-description-content-title
        font-size: 16px
        line-height: 24px
        margin: 0
        color: var(--color-primary)

      .autolearn-description-content-message
        font-size: 14px
        font-weight: 500
        line-height: 20px
        margin: 0 !important

        p
         width: 100%

      .spinner

        svg
          width: 26px
          height: 26px


.autolearn-description-content-title
  font-size: 20px
  font-weight: 500
  line-height: 30px
  margin: 24px 0 7px 0
  text-transform: uppercase

.autolearn-description-content-message
  display: flex
  flex-direction: column
  align-items: center
  font-size: 16px
  font-weight: 500
  line-height: 24px
  color: var(--text-heading2-subtitle)
  margin-bottom: 24px

.autolearned-devices-table-title
  font-size: 14px
  font-weight: 500
  line-height: 16px
  letter-spacing: 0
  text-align: left
  padding: 16px
  background-color: var(--background-success)
  border-bottom: 1px solid var(--line-general)

.autolearned-devices-table
  flex: 10
  width: 530px
  margin: 0
  border: 1px solid var(--line-general)

  .table-content
    max-height: 270px
    overflow: auto

    .table-cell:first-child
      .table-cell-content
        padding-left: 16px

        svg
          width: 24px
          height: 24px

  .table-header
    background-color: var(--background-success)
    border-bottom: 1px solid var(--line-general)

    .table-header-cell
      padding-top: 8px
      &:first-child
        padding-left: 16px

.autolearn-card-actions-wrapper
  display: flex
  justify-content: space-between
  padding-left: 16px

.autolearn-device-card-wrapper
  width: 530px
  height: 190px
  padding-top: 24px
  border-top: 2px solid var(--line-general)
  display: flex
  align-items: center
  flex-direction: column

  .autolearn-device-card
    display: flex
    flex-direction: row
    gap: 17px
    padding: 17px
    margin-bottom: 24px
    width: 530px
    height: 102px
    border: 2px solid var(--line-general)

    svg
      width: 64px
      height: 64px

    .autolearn-device-image
      width: 64px
      height: 64px
      background-size: contain
      background-repeat: no-repeat
      background-position: center

    .autolearn-device-content
      padding: 12px 0

.autolearn-device-type-field
  font-size: 12px
  font-weight: 500
  line-height: 14px
  letter-spacing: 0
  text-align: left
  text-transform: uppercase
  color: var(--color-primary)
  margin-bottom: 12px

.autolearn-device-enroll-id-field
  font-size: 14px
  font-weight: 500
  line-height: 16px
  letter-spacing: 0
  text-align: left

.autolearn-pin-input-fields
  width: 100%
  display: flex
  justify-content: center

  .autolearn-pin-input-field
    width: 40px
    height: 44px
    background-color: var(--background-partition-label)
    border: 1px solid var(--line-general)
    margin-right: 7px
    border-radius: 2px

    .form-field
      height: 44px

      .input-field
        height: 44px
        padding-left: 16px

.autolearn-zwave-pin-template
  display: flex
  flex-direction: row
  justify-content: center
  margin: 24px 0
  align-items: center

  .hidden-part
    font-size: 18px
    line-height: 26px
    color: var(--color-primary)
    margin-right: 6px

  .open-part
    font-size: 16px
    line-height: 24px
    color: var(--text-heading1)

.autolearn-zwave-toggle-group
  border-top: 2px solid var(--line-general)
  padding-top: 40px
  width: 100%

  div
    font-size: 14px
    font-weight: 500
    line-height: 20px
    letter-spacing: 0
    text-align: left
    color: var(--text-heading2)


  .form-field--togglebox
    display: flex
    flex-direction: row-reverse
    justify-content: space-between
    margin: 0 0 16px 0

    .checkbox-label
      margin-left: 0
      font-size: 14px
      font-weight: 400
      line-height: 16px
      letter-spacing: 0
      text-align: left
      color: var(--text-heading2-subtitle)

.autolearn-idle-form

  .autolearn-device-card-wrapper

    .form-field
      width: 257px
      margin: 4px auto

[dir = 'rtl']
  .autolearn-pin-input-fields
    .autolearn-pin-input-field
      .form-field
        .input-field
          padding-right: 14px
          padding-left: 0

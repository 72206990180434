$navBlindWidth: 400px

.navBlind
  width: $navBlindWidth
  top: 0
  right: 439px
  z-index: 3
  position: absolute
  height: 100%

.navBlind-title
  height: 72px
  padding: 24px
  font-size: 17px
  font-weight: bold
  border-bottom: 1px solid var(--line-general)

.btn.navBlind-close
  position: absolute
  right: 7px
  top: 8px

  .btn-icon
    color: var(--color-gray-600)

.navBlind-list
  overflow: auto
  height: 100% // header height
  padding: 8px 11px 13px
  scrollbar-width: thin
  scrollbar-color: var(--color-primary30) var(--background-primary)
  display: flex
  flex-direction: column
  row-gap: 4px

.navBlind-list-moreBtnHolder
  display: flex
  justify-content: center
  padding: 16px 0

  .btn
    width: 66.6%


@keyframes openNavBlindLarge
  from
    transform: translate3d(403px, 0, 0)
  to
    transform: translate3d(653px, 0, 0)

@keyframes openNavBlindMedium
  from
    transform: translate3d(var(--main-asside-width-medium) - $navBlindWidth, 0, 0)
  to
    transform: translate3d(var(--main-asside-width-medium), 0, 0)

@keyframes moveToRight
  0%
    right: -250px
    opacity: 0

  35%
    right: 0
    opacity: 0.5

  100%
    right: 403px
    opacity: 1


[dir="rtl"]
  .navBlind
    right: unset
    left: 440px

  .btn-icon--offset
    margin-right: unset
    margin-left: 8px


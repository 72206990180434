.charts
  .title
    display: flex
    justify-content: space-between
    align-items: center

.chart
  min-height: 200px


.chartModal
  .bar
    margin-bottom: 16px
  .form-field--datepicker
    width: 168px
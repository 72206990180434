

.faultChecklist
  padding: 12px 0
  margin: -12px 0

  .form-scroll
    margin-top: 12px
    padding-top: 0

.faultChecklist-row
  margin-top: 10px

.faultChecklist-row-text
  margin-left: 24px
  font-size: var(--font-size-small)
  color: var(--text-heading2-subtitle)

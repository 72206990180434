

$colorKeypadLcdBackground: #73AD4E

.keypad
  padding: 0 16px 0 0

  > .bar
    margin: 12px 0

  > .layout
    padding-bottom: 16px
    flex-grow: 0
    flex-shrink: 1
    height: auto
    justify-content: space-between

.keypad-panel, .keypad-log
  display: flex
  flex-direction: column
  align-items: stretch
  width: calc(50% - 12px)

  .bar
    margin-bottom: 10px

@media screen and (min-width: $window-medium)
  .keypad
    > .layout
      justify-content: space-evenly

  .keypad-panel, .keypad-log
    width: 40%
    max-width: 500px

.keypad-panel
  overflow-y: auto
  padding: 24px 40px

  .processLoader
    position: absolute
    z-index: 5
    left: 0
    top: 0
    width: 100%
    height: 100%
    border-radius: 2px
    background-color: var(--background-keypad-shadow)

.keypad-panel--neo
  .keypad-bar-lcd
    height: calc(2 * var(--bar-height))

    .lcd-text
      height: 2em*1.3

  .keypad-digits
    .keypad-digit-container
      &:nth-child(1),
      &:nth-child(2)
        width: 50%

      &:nth-child(-n+3)
        margin-top: 15px
      &:nth-child(-n+6) .btn
        background-color: initial

      &:nth-child(-n+2)
        margin-top: 0

        .btn
          background-color: var(--button-light)

.keypad-log
  max-height: 600px

  .bar
    padding: 20px
    height: auto

    .form-fields-item
      margin-right: 20px

.keypad-bar
  padding: 0 24px
  background: linear-gradient(to bottom, #212121, #000000)
  border-radius: 2px
  margin-bottom: 12px
  justify-content: space-around

.keypad-bar-lcd
  color: $colorKeypadLcdBackground
  padding: 12px

  .lcd-text
    $charWidth: 9px
    height: 1em
    width: calc(#{$charWidth} * 16 + 1px)
    position: relative
    font-family: monospace
    word-wrap: break-word

    > span
      width: $charWidth

  .lcd-caret
    animation: caretBlinker 1000ms step-end infinite

  .lcd-caret--block
    animation: caretBlockBlinker 1000ms step-end infinite

.led
  opacity: .3

.led--active
  opacity: 1
  filter: drop-shadow(0 0 2px rgba(255, 255, 255, .6))

.led--blink
  animation: ledBlinker 2s linear infinite

.led--one-wink
  animation: ledBlinker 460ms linear infinite

.led--double-wink
  animation: ledBlinker 500ms linear 2
  animation-delay: 500ms

.led--triple-wink
  animation: ledBlinker 500ms linear 3
  animation-delay: 500ms

.led--long-wink
  animation: ledBlinker 800ms linear infinite
  animation-delay: 1300ms

.led--long-double-wink
  animation: ledBlinker 800ms linear 2
  animation-delay: 1300ms

.led--long-triple-wink
  animation: ledBlinker 800ms linear 3
  animation-delay: 1300ms

.led--flash-slow
  animation: ledBlinker 2000ms linear infinite

.led--flash-medium
  animation: ledBlinker 1600ms linear infinite

.led--flash-fast
  animation: ledBlinker 1000ms linear infinite

.led--flash-status-one
  animation: ledBlinker 800ms linear infinite
  animation-delay: 2000ms

.led--flash-status-two
  animation: ledBlinker 800ms linear 2
  animation-delay: 2000ms

.led--flash-status-three
  animation: flashStatusThree 300ms linear infinite

.led--armed-sleep-mode
  animation: armedSleepMode 5100ms linear infinite

.led--armed-stop-mode
  animation: armedStopMode 1000ms linear infinite

.led--system-test
  animation: ledBlinker 500ms linear 5

.led--power
  color: #00C501

.led--away
  color: #E60000

.led--alarm
  color: #FFEE58

.led--alert
  color: #E27C00

.led--network
  color: #007AFF

.led--amdocs
  color: #00C9FF

.led--wi-fi
  color: #00C501

.led--ac
  color: #00C501

.keypad-keys-container
  margin: auto
  margin-top: 20px

.keypad-digits
  display: flex
  justify-content: center
  flex-wrap: wrap

  .keypad-digit-container
    justify-content: center
    display: flex
    width: calc(100% / 3)
    margin-top: 15px

    &:nth-child(-n+3)
      margin-top: 0
    &:nth-child(-n+6) .btn
      background-color: var(--button-light)

    &:nth-child(2) .btn svg
      position: relative
      left: 1px
      top: -1px

    &:nth-child(4) .btn svg
      position: relative
      left: -1px

    &:nth-child(6) .btn svg
      position: relative
      left: 1px

  .btn
    border-radius: 50%
    width: 40px
    height: 40px

    .btn-content
      padding: 0

.keypad-log-list
  padding: 10px 20px

  .list-item
    display: flex
    align-items: baseline
    margin-top: 5px

    .hint
      margin-right: 10px
      color: var(--text-heading2-subtitle)

    strong
      font-weight: 500

.keypad-filter
  padding: 12px

@keyframes caretBlinker
  50%
    text-decoration: underline

@keyframes caretBlockBlinker
  50%
    color: mix(#212121, #000000)
    background-color: $colorKeypadLcdBackground

@keyframes ledBlinker
  50%
    opacity: 1

@keyframes flashStatusThree
  66%
    opacity: 1

@keyframes armedSleepMode
  2%
    opacity: 1

@keyframes armedStopMode
  10%
    opacity: 1

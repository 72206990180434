.centralStation-communication-pager
  margin-right: 8px

.central-station-tabs
  margin-top: -16px
  .tooltip
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis

[dir = 'rtl']
  .centralStation-communication-pager
    margin-right: unset
    margin-left: 8px

  .centralStation-general
    .card-header
      .definition
        display: inline-flex
        direction: rtl !important

  .centralStation-communication
    margin-right: -16px

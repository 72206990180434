

.pgm-form
  .form-content
    height: 100%
    display: flex
    flex-direction: column

.pgm-form-field-title
  margin: 16px 0

.pgm-form-field-description
  font-size: 12px
  color: var(--color-gray-600)
  margin-top: 4px

.pgm-form-field-description--open
  .pgm-form-field-description-info
    max-height: 200px
    overflow: auto
    padding: 8px

.pgm-form-field-description-trigger
  color: var(--color-gray-600)
  width: 100%
  text-align: right
  height: 24px
  line-height: 24px

  .btn-content
    text-transform: none
    font-size: 12px
    display: flex
    justify-content: flex-end
    align-items: center

  .btn-icon
    width: 16px
    height: 16px
    margin-right: 0
    margin-left: 8px
    order: 1
    transform: translateY(-1px)

.pgm-form-field-description-info
  max-height: 0
  overflow: hidden
  transition: max-height  var(--time), padding  var(--time)

.pgm-form-submit
  margin-top: 16px
  align-self: flex-start

.pushBasicConfiguration-header
  display: flex
  margin-top: 8px
  margin-right: 44px

.pushBasicConfiguration-btn--goBack
  margin-right: 12px

.pushBasicConfiguration-search
  width: 100%

[dir = 'rtl']
  .pushBasicConfiguration-header
    margin-right: 7px
    .pushBasicConfiguration-btn--goBack
      margin-right: 0
      margin-left: 12px

.init-emergency-modal
  .init-emergency-card
    width: 650px !important

  .card-actions
    width: 100%
    position: absolute
    bottom: 0
    right: 0

    .init-emergency-card-actions
      margin-right: 16px
      display: flex
      justify-content: space-between

  .form-content
    .card-content
      &:has(.init-emergency-chosen-block)
        padding-bottom: 160px

    .card-content
      display: flex
      flex-direction: column
      padding: 16px 32px 70px

      hr
        margin: 24px 0

      .init-emergency-block-content-disclaimer
        display: flex
        flex-direction: column
        align-items: center

        .init-emergency-block-content-image
          margin-bottom: 24px

          svg
            width: 60px
            height: 60px

        .disclaimer
          display: flex
          align-items: center
          margin-top: 24px
          margin-bottom: 16px
          border-radius: 2px

          h2
            font-size: 14px
            font-weight: 500
            line-height: 20px

          h3
            font-size: 14px
            font-weight: 500
            line-height: 20px
            color: var(--text-heading2-subtitle)

          svg
            width: 22px
            height: 25px
            margin-left: 10px
            margin-right: 22px

        h1
          text-transform: uppercase
          margin-bottom: 7px
          font-weight: 500
          font-size: 20px
          line-height: 30px

        h3
          font-weight: 500
          font-size: 16px
          line-height: 24px
          color: var(--text-heading2-subtitle)

    .card-header
      color: var(--text-heading1)
      padding: 24px 32px

  .init-emergency-block-content-wrapper
    margin-bottom: 10px
    padding: 24px
    background-color: var(--background-primary)

    .init-emergency-block-toggle
      display: flex
      justify-content: space-between
      align-items: center
      padding: 6px 0

      .form-field
        margin-top: 0

      .init-emergency-block-title-block
        font-size: 14px
        font-weight: 500

      svg
          width: 16px
          height: 18px
          margin-right: 16px
          color: var(--icon-default)

    .init-emergency-block-content
      display: flex
      width: 100%
      justify-content: space-between

      .init-emergency-block-title-block
        display: flex
        align-items: center

        .init-emergency-block-title
          h1
            font-size: 16px
            line-height: 24px
            font-weight: 500
            color: var(--text-heading1)

          h3
            color: var(--text-heading2-subtitle)
            font-size: 14px
            font-weight: 400
            line-height: 24px

    svg
          margin-right: 16px

[dir = 'rtl']
  .init-emergency-modal
    .init-emergency-block-content-wrapper
      svg
        margin-right: 0
        margin-left: 16px

    .card-actions
      .init-emergency-card-actions
        margin-right: 0
        margin-left: 16px

      .btn
        &:not(:last-child)
          margin-right: 0
          margin-left: 8px

    .form-content .card-content
      .init-emergency-block-content-disclaimer
        .disclaimer
          svg
            margin-left: 22px
            margin-right: 10px

.panelBillingBlind
  &__serial
    margin-left: 16px

  &__footer
    display: flex
    justify-content: flex-end

.panelBillingCurrentPlan
  margin: 0 32px
  font-size: 14px
  font-weight: 500

  &__title
    color: var(--text-heading1)

  &__items
    display: grid
    grid-template-columns: repeat(2, 1fr)
    gap: 4px
    margin-top: 10px

  &__item
    border-radius: 2px
    padding: 12px 16px
    display: flex
    align-items: center
    gap: 12px
    border: 2px solid var(--line-general)

    > svg
      width: 24px
      height: 24px
      color: var(--text-heading2-subtitle)

    &--pending
      > svg
        animation-name: icon-rotate
        animation-iteration-count: infinite
        animation-duration: 900ms

    &-name
      color: var(--text-heading2-subtitle)

    &-value
      font-size: 16px
      color: var(--text-heading1)
      line-height: 24px
      display: inline-flex
      align-items: baseline
      flex-direction: row
      flex-wrap: nowrap
      align-content: center
      justify-content: flex-start
      gap: 4px

      > button
        line-height: var(--font-size-medium)
        height: var(--font-size-medium)

.panelBillingAllPlans
  margin: 0 32px
  font-size: 14px

  &__title
    color: var(--text-heading1)

  &__items
    margin-top: 10px

.panelBillingPlan
  border-radius: 2px
  background: var(--background-primary)
  padding: 24px
  margin-bottom: 10px

  &:not(&--active):hover
    background: var(--table-hover)

  &--active
    border: 1px solid var(--color-primary)
    background: var(--table-active)

  &--requested
    border: 1px solid var(--color-primary)

  &__info
    display: flex
    justify-content: space-between
    align-items: center

  &__choose
    gap: 24px
    display: flex
    align-items: center

  &__choose-button:disabled
    background-color: var(--color-primary)

    .btn-content
      opacity: 0.38

  &__price
    font-size: 28px
    line-height: 36px

  &__description
    line-height: 24px
    margin-top: 24px

.panelBillingPlanBadge
  border-radius: 2px
  text-transform: uppercase
  padding: 4px 12px 4px 8px
  font-size: 12px
  font-weight: 500
  line-height: 16px
  background: var(--color-error)
  display: inline-flex
  align-items: center
  gap: 4px
  max-width: max-content
  overflow: hidden
  text-overflow: ellipsis
  justify-content: flex-start

  > svg
    width: 16px
    height: 16px
    flex-shrink: 0

  &--pending
    > svg
      animation-name: icon-rotate
      animation-iteration-count: infinite
      animation-duration: 900ms

  > span
    flex-shrink: 1

  &--short
    padding: 4px

  &--callable
    cursor: pointer

  &--panel-top
    height: 36px

  &--N00E
    background: var(--color-error)

  &--M01R
    background: #CCE7FF
    color: #0E4C85

  &--Y01R
    background: #CCFFD1
    color: #1F850E

  &--Y02R
    background: #F8FFCC
    color: #85790E

  &--Y03R
    background: #FFE4CC
    color: #855D0E

  &:not(.panelBillingPlanBadge--short).panelBillingPlanBadge
    &--N00E
      box-shadow: 0 0 10px 0 #CA233999

    &--M01R
      box-shadow: 0 0 10px 0 #0E4C8599

    &--Y01R
      box-shadow: 0 0 10px 0 #1F850E99

    &--Y02R
      box-shadow: 0 0 10px 0 #85790E99

    &--Y03R
      box-shadow: 0 0 10px 0 #855D0E99

.panelBillingDeactivateConfirmation
  .btn.btn--primary
    background-color: var(--color-error)

.panelBillingActivateModal
  &__description
    color: var(--text-heading2-subtitle)

[dir=rtl]
  .panelBillingBlind
    &__serial
      margin-right: 16px
      margin-left: auto

    .card-header
      padding: 24px 32px

    .card-header-close
      margin-left: 0

.formGrid
  display: grid
  grid-row-gap: 16px

.formGrid--modalCardForm
  display: initial

  &.form.card .form-content .card-content
    @extend .formGrid

.formGrid--twoCols
  grid-template-columns: 1fr 1fr
  grid-column-gap: 16px

.formGrid--twoCols.formGrid--modalCardForm
  &.form.card .form-content .card-content
      @extend .formGrid--twoCols

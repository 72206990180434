.connection-status
  margin: 0 -32px

  table
    width: 100%
    font-weight: 500

    thead
      color: var(--text-heading2-subtitle)
      background-color: var(--background-success)
      font-size: var(--font-size-small)
      text-transform: uppercase

      th
        height: 30px !important

    tbody
      font-size: var(--font-size-primary)

    tr
      td:last-child:not(.connection-name)
        padding-right: 32px

      th
        line-height: 12px

      .connection-encryption
        color: var(--text-heading2-subtitle)

      td, th
        vertical-align: middle
        text-align: left
        height: 44px
        border-bottom: 1px solid var(--line-general)
        font-weight: 400

        &.host-port-header
          padding-left: 12px

        &.protocol-header
          width: 138px
          min-width: 138px
          padding: 8px 12px 8px 32px

        &.channel-header
          width: 150px
          padding: 8px 12px

          &:last-child
            width: 100%

        &.connection-name
          color: var(--color-primary)
          text-align: center
          height: 36px

        &.connection-name-short
          color: var(--color-primary)
          text-align: left
          padding: 0 0 0 32px

          span
            padding: 8px 12px 8px 0
            width: 94px
            max-width: 94px
            overflow: hidden
            text-overflow: ellipsis

        &.channel-encryption-header
          width: 200px
          max-width: 200px
          min-width: 200px
          text-overflow: ellipsis
          overflow-x: hidden
          padding: 8px 12px 8px 32px
          white-space: nowrap

        &.buttons-header
          width: 150px
          max-width: 150px

        &.buttons-cell
          display: flex
          width: 100%
          justify-content: flex-end
          align-items: center
          height: 68px
          padding: 0 32px 0 8px

          .btn
            height: 28px
            width: initial
            padding: 0 16px
            display: flex
            flex-direction: column
            align-items: center
            justify-content: center
            line-height: 12px

            .btn-content
              font-size: 12px

        &.host-port-cell
          max-height: 68px
          color: var(--text-heading2-subtitle)
          padding-left: 12px

          > div
            display: flex
            align-items: center

            svg
              width: 16px
              height: 16px
              margin-right: 8px

        &.channel-encryption-cell, &.channel-cell
          padding: 0 12px 0 32px
          width: 165px
          height: 68px

          > div
            display: flex
            width: 100%
            flex-direction: row
            align-content: center
            align-items: center
            gap: 16px

          svg
            width: 20px
            height: 20px

          .channel-encryption-col
            display: flex
            flex-direction: column
            gap: 4px

            .connection-encryption
              color: var(--text-heading2-subtitle)

        &.channel-cell
          padding: 8px 12px

  .buttons-container
    padding-left: 10px
    display: flex
    justify-content: flex-end
    align-items: center

    .dls-connection-buttons
      height: 28px
      width: 111px
      font-size: 12px
      line-height: 14px
      display: flex
      flex-direction: row
      justify-content: center
      align-items: center

      .btn-content
        padding: 0

.dls-connection-buttons-drop-down-slide
  transition: unset

  .menu-item-icon
    opacity: 1

[dir = 'rtl']
  .connection-block
    .buttons-container
      padding-right: 0
      padding-left: 32px

    .connection-block-title-cell
      padding-left: 0
      padding-right: 32px

    .connection-block-title
      text-align: right
      margin-left: auto
      margin-right: 0

  .connection-status
    table
      tr
        th:first-child
          padding-right: 32px
          text-align: right

      td
        &.connection-encryption
          padding-right: 0 !important
          text-align: right

        &.channel-encryption-cell
          padding: 0 32px 0 12px

          .channel-encryption-col
            text-align: right

        &.host-port-cell
          text-align: right
          padding-left: 0
          padding-right: 12px !important

          svg
            margin-right: 0
            margin-left: 4px

        &.connection-name-short
          text-align: right
          padding: 0 32px 0 0

          span
            padding: 8px 0 8px 12px

      th
        &.host-port-header, &.channel-header, &.encryption-header
          text-align: right

        &.host-port-header
          padding-left: 0
          padding-right: 12px

.recentHistory-item-infoWrapper
  padding: 34px 0
.recentHistory-info-wrapper
  width: 100%
.recentHistory-item--active
  .recentHistory-item-header-dropDownIcon
    transform: rotate(180deg)
  .recentHistory-item-header
    background-color: var(--table-hover)

.recentHistory-item-header
  display: flex
  position: relative
  cursor: pointer
  transition: background-color  var(--time) var(--ease-out-sine)

  &:hover
    background-color: var(--table-hover)

.recentHistory-item-header-dropDownIcon
  position: absolute
  right: 15px
  top: 50%
  transition: transform calc(var(--time) / 2) var(--ease-out-cubic)

.recentHistory-item-infoWrapper
  padding: 10px 15px 10px 0
  width: 100%
  display: flex
  flex-direction: row
  flex-wrap: nowrap
  align-content: center
  align-items: center
  justify-content: flex-start
  height: 100%

.recentHistory-item-info-iconWrapper
  width: 40px
  height: 40px
  border: solid 1px var(--line-general)
  border-radius: 50%
  padding: 7px 9px
  margin: 12px 12px 12px 8px
  display: inline-block
  svg
    color: var(--icon-primary)

.recentHistory-item-info + .recentHistory-item-info
  margin: 7px 0

.recentHistory-item-info--panelSerial
  font-style: normal
  font-weight: 500
  font-size: 14px
  line-height: 16px
  letter-spacing: 0.2px
  color: var(--text-heading1)

.recentHistory-account
  font-size: 12px
  font-weight: 500
  line-height: 14px
  margin-left: 10px
  padding-left: 10px
  border-left: solid 1px var(--text-heading2-subtitle)
  color: var(--text-heading2-subtitle)

.recentHistory-item-info--userEmail
  font-style: normal
  font-weight: 400
  font-size: 12px
  line-height: 14px
  display: flex
  width: 100%
  flex-direction: row
  flex-wrap: nowrap
  color: var(--text-heading2-subtitle)
  .tooltip
    text-overflow: ellipsis
    overflow: hidden
    width: 90%

.recentHistory-visitTime
  font-weight: 400
  font-size: 12px
  line-height:  14.06px
  color: var(--text-heading2-subtitle)

.recentHistory-item-panelList
  overflow: auto
  background-color: var(--line-general)
  margin-top: 4px
  transition: all ease-in-out var(--time)

  .spinner-container
    display: none
    margin: 24px auto

.recentHistory-item-panelList-closed
  max-height: 0

.recentHistory-item-panelList-opened
  max-height: 1000px

.recentHistory-item-panelList-item, .recentHistory-item-panelList-title
  padding: 0 64px

.recentHistory-item-panelList-title
  margin-bottom: 6px
  margin-top: 32px
  font-size: 12px
  font-weight: 500
  color: var(--text-heading2-subtitle)
  text-transform: uppercase


.recentHistory-item-panelList-item
  height: 44px
  border-bottom: 4px solid var(--card-primary)
  display: flex
  align-items: center
  border-radius: 2px

  .definition-detail
    padding-left: 5px
    margin-left: 5px
    border-left: solid 1px var(--text-heading2-subtitle)

  &:hover
    background-color: var(--background-success)

.recentHistory-item
  margin: 0 16px 0 0
  border-radius: 2px

.recentHistory-wrapper
  height: 94% !important
  flex-grow: 1
  overflow-y: auto
  .table
    margin-right: 0
    .table-row
      border: none
    .table-content
      box-shadow: none
    .table-cell-content
      padding: 0
    .table-title
      text-transform: none
      text-align: left
      background-color: var(--card-primary)
      font-style: normal
      font-weight: 500
      font-size: 18px
      line-height: 26px
      padding: 24px 0 0 8px
      height: 60px
      &:not(:first-child)
        margin-top: -2px

[dir="rtl"]
  .recentHistory-wrapper
    .table-title
      text-align: right
      padding: 24px 8px 0 0

  .recentHistory-item
    margin: 0 0 0 16px

  .recentHistory-item-panelList-item
    .definition-detail
      padding-right: 5px
      padding-left: unset
      margin-right: 5px
      margin-left: unset
      border-right: solid 1px var(--text-heading2-subtitle)
      border-left: none

  .recentHistory-account
    margin-left: unset
    margin-right: 10px
    padding-left: unset
    padding-right: 10px
    border-right: solid 1px var(--text-heading2-subtitle)
    border-left: none

  .recentHistory-item-info--panelSerial
    display: flex

  .recentHistory-item-header-dropDownIcon
    right: unset
    left: 15px

  .recentHistory-item-infoWrapper
    padding: 10px 0 10px 15px

  .recentHistory-item-info-iconWrapper
    margin: 12px 8px 12px 12px
